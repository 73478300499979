import { Grid, styled } from '@mui/material';
import loginImage from '../../assets/images/landing-background.jpg';
import { CColorCodeVariables } from '../../constants/color.constants';

export const SignupPageContainerStyled = styled(Grid)<{ ismobile: string }>(({ theme, ismobile }) => ({
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${loginImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& .left-grid-item': {
        position: 'relative',
        height: '100%',
        width: ismobile === "true" ? '100%' : '50%',
        '.agrimor-logo-light': {
            height: '87px',
            width: '61px',
            position: 'absolute',
            bottom: '5%',
            left: '45%',
        },
    },
    '& .right-grid-item': {
        backgroundColor: `rgba(${CColorCodeVariables.white}, 0.95)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: ismobile === "true" ? '100%' : '50%',
        '& .box-wrap': {
            width: '400px',
            background: 'none',
            border: 'none',
            padding: '20px',
            '& .grid-container': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                '& .agrimor-logo-wrap': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .agrimor-logo-dark': {
                        height: '87px',
                        width: '61px',
                    }
                },
                '& .title': {
                    fontWeight: '600',
                    fontSize: '48px',
                    lineHeight: '60px',
                    marginBottom: '40px',
                    color: `rgba(${CColorCodeVariables.shadowSlate}, 0.6)`,
                    textAlign: 'center'
                },
                '& .label': {
                    color: `rgba(${CColorCodeVariables.black}, 0.85)`,
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16.8px',
                    marginBottom: '5px'
                },
                '& .input-field': {
                    borderRadius: '4px',
                    padding: 0,
                    backgroundColor: 'white',
                },
                '& .select-field': {
                    borderRadius: '4px',
                    padding: 0,
                    backgroundColor: 'white',
                },
                '& .error-msg': {
                    color: 'red'
                },
                '& .pass-err-wrap': {
                    border: `1px solid rgba(${CColorCodeVariables.black}, 0.25)`,
                    borderRadius: '4px',
                    padding: '8px',
                    backgroundColor: `rgba(${CColorCodeVariables.white}, 0.2)`,
                    marginBottom: '5px',
                    '& .display-msg': {
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: '1.66',
                        textAlign: 'left'
                    }
                },
                '& .submit-btn': {
                    width: '100%',
                    height: '44px',
                    borderRadius: '4px',
                    padding: '11.5px, 20px, 11.5px, 20px',
                    background: 'linear-gradient(45deg, #00B09B 30%, #3DB0C9 90%)',
                    fontWeight: 500,
                    fontSize: '15px',
                    letterSpacing: '1.25px',
                    color: `rgba(${CColorCodeVariables.white}, 1)`,
                    '&:disabled': {
                        color: `rgba(${CColorCodeVariables.black})`,
                        border: '1px solid #bfb9b9',
                        borderRadius: '4px',
                        cursor: 'not-allowed',
                        opacity: theme.palette.action.disabledOpacity,
                        background: theme.palette.action.disabledBackground
                    }
                },
                '& .register-wrap': {
                    color: `rgba(${CColorCodeVariables.black}, 0.85)`,
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16.8px',
                    marginBottom: '5px',
                    textAlign: 'center'
                }
            }
        }
    }
}));

export const SignupSuccessAndUnsuccessPageStyled = styled(Grid)<{ ismobile: string }>(({ theme, ismobile }) => ({
    height: '100vh',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundImage: `url(${loginImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& .box-wrap': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `rgba(${CColorCodeVariables.white}, 0.95)`,
        '& .grid-container': {
            width: '100%',
            padding: '20px',
            '& .title': {
                fontWeight: '600',
                fontSize: ismobile === "true" ? '2.5rem' : '3rem',
                lineHeight: ismobile === "true" ? '50px' : '60px',
                marginBottom: '20px',
                color: `rgba(${CColorCodeVariables.shadowSlate}, 0.5)`,
                textAlign: 'center',
                overflowWrap: 'break-word'
            },
            '& .content': {
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '27px',
                color: `rgba(${CColorCodeVariables.black}, 0.5)`,
                textAlign: 'center',
            },
            '& .icon-wrap': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '80px 0px',
                '& .reg-icon': {
                    height: '168px',
                    width: '168px'
                }
            },
            '& .btn-wrap': {
                display: 'flex',
                justifyContent: 'center',
                alignItem: 'center',
                '& .back-btn': {
                    marginTop: '20px',
                    width: '360px',
                    height: '44px',
                    borderRadius: '4px',
                    padding: '11.5px, 20px, 11.5px, 20px',
                    background: 'linear-gradient(45deg, #00B09B 30%, #3DB0C9 90%)',
                    fontWeight: 500,
                    fontSize: '15px',
                    letterSpacing: '1.25px',
                    color: `rgba(${CColorCodeVariables.white}, 1)`,
                },
                '& .return-btn': {
                    marginTop: '20px',
                    width: '360px',
                    height: '44px',
                    borderRadius: '4px',
                    padding: '11.5px, 20px, 11.5px, 20px',
                    background: '#FFFFFF66',
                    fontWeight: 500,
                    fontSize: '15px',
                    letterSpacing: '1.25px',
                    color: '#00000099',
                }
            }
        }
    }
}));
