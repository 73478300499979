import { FC, useEffect, useState } from "react"
import { StyledIntelligentControlSwitchSkeleton } from "./StyledIntelligentControlSwitchSkeleton";
import { Skeleton } from "@mui/material";

interface IntelligentControlSwitchSkeletonProps {
    indexValue: number;
    controlType: "Aerators" | "Feeders";
    activeControlIndex: number;
}

const IntelligentControlSwitchSkeleton: FC<IntelligentControlSwitchSkeletonProps> = ({ indexValue, controlType, activeControlIndex, ...rest }) => {
    const [isActive, setIsActive] = useState<boolean>(indexValue === activeControlIndex ? true : false);

    useEffect(() => {
        setIsActive(indexValue === activeControlIndex ? true : false);
    }, [activeControlIndex, setIsActive, indexValue])

    return (
        <StyledIntelligentControlSwitchSkeleton isactive={isActive.toString()}>
            <div className="header">
                <div className="title-wrap">
                    <Skeleton className="title" animation="wave" />
                </div>
                <div className="img-indicator">
                    <Skeleton className="indi-icon" animation="wave" />
                </div>
            </div>
            <div className="content">
                <div className="para-row">
                    <Skeleton className="label-col" animation="wave" />
                    <span className="status-col">
                        <Skeleton className="switch" animation="wave" />
                    </span>
                </div>

                <div className="para-row">
                    <Skeleton className="label-col" animation="wave" />
                    <span className="status-col">
                    <Skeleton className="switch" animation="wave" />
                    </span>
                </div>
            </div>
            <div className="footer">
                <div className="history">
                    <Skeleton className="date-time" animation="wave" />
                </div>
            </div>
        </StyledIntelligentControlSwitchSkeleton>
    )
}

export default IntelligentControlSwitchSkeleton;