import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorFallback from "./common/ErrorFallback";
import Root from './root/Root'
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import ChangePasswordPage from "./pages/change-password/ChangePasswordPage";
import LoginPage from "./pages/login/LoginPage";
import SignupPage from "./pages/signup/SignupPage";
import ProtectedRoute from "./root/ProtectedRoute";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LayoutContainer from "./components/layout-container/LayoutContainer";
import RedirectToDashboardOnLogin from "./root/RedirectToDashboardOnLogin";

const AppRoutes = () => {

    const appRoutes = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            children: [
                {
                    path: '/',
                    element: <LayoutContainer />,
                    children: [
                        {
                            path: '/',
                            element: <ProtectedRoute><DashboardPage /></ProtectedRoute>
                        },
                    ]
                },
                {
                    path: '/forgot-password',
                    element: <RedirectToDashboardOnLogin><ForgotPasswordPage /></RedirectToDashboardOnLogin>
                },
                {
                    path: '/change-password/:token',
                    element: <RedirectToDashboardOnLogin><ChangePasswordPage /></RedirectToDashboardOnLogin>
                },
                {
                    path: '/login',
                    element: <RedirectToDashboardOnLogin><LoginPage /></RedirectToDashboardOnLogin>
                },
                {
                    path: '/signup',
                    element: <RedirectToDashboardOnLogin><SignupPage /></RedirectToDashboardOnLogin>
                },
                {
                    path: '/404',
                    element: <ErrorFallback />
                }
            ],
            errorElement: <ErrorFallback />
        }
    ])

    return (
        <RouterProvider router={appRoutes} />
    )
}

export default AppRoutes