import { useState } from 'react';
import './App.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MUI_THEME_MODE, getDesignTokens } from './constants/themes.constants';
import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Toast from './common/Toast';
import NotificationAlert from './common/notification-alert/NotificationAlert';
import {QueryClient, QueryClientProvider} from "react-query";

const App = () => {
  const theme = MUI_THEME_MODE.LIGHT;
  const appTheme = createTheme(getDesignTokens(theme === MUI_THEME_MODE.DARK ? MUI_THEME_MODE.DARK : MUI_THEME_MODE.LIGHT));

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={appTheme}>
        <Provider store={store} >
          <AppRoutes />
          <NotificationAlert />
          <Toast />
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
