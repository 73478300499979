import { configureStore } from '@reduxjs/toolkit'
import toastSlice from './slices/toastSlice'
import userDetailsSlice from './slices/userSlice';
import { reducer as notificationReducer } from './slices/notificationSlice';

export const store = configureStore({
    reducer: {
        toast: toastSlice,
        userDetails: userDetailsSlice,
        notification: notificationReducer,
    },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;