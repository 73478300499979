import { styled } from "@mui/material"; 
import { CColorCodeVariables } from "../../../constants/color.constants";

export const StyledControlStatusChart = styled('section')(({ theme }) => ({
    background: `rgba(${CColorCodeVariables.paleSilver}, 1)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    borderRadius: '8px',
    marginLeft: '0.8rem',
    height: '471px',
    [theme?.breakpoints?.down('md')]: {
        marginLeft: '0',
        marginTop: '0.8rem'
    }
}))