import "./SideNavigationBar.scss";
import NavLogo from "../../assets/icons/Agrimor Outline black-Dark gray.svg";
import LogoutLogo from "../../assets/icons/Icon _ Logout.svg";
import DashboardLogo from "../../assets/icons/Icon _ Dashboard.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES_FOR_NAVIGATION } from "../../constants/routes.contants";
import { logout } from "../../services/api-service/authApi";
import { CLocalStorageItems } from "../../constants/service.contants";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../services/local-storage-service/localStorage";
import { useAppDispatch } from "../../store/hooks";
import { clearUserDetails } from "../../store/slices/userSlice";
import { Tooltip } from "@mui/material";

const selectedStyle = "selectedNav";

const SideNavigationBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userLogout = async () => {
    const sessionId =
      getLocalStorageValue(CLocalStorageItems.sessionLogin) || "";
    const apiResponse = await logout(sessionId);
    if (apiResponse.status) {
      const theme = getLocalStorageValue("theme");
      localStorage.clear();
      setLocalStorageValue("theme", theme);
      navigate("/login");
      dispatch(clearUserDetails());
    }
  };

  return (
    <nav className="navWrapper">
      <img
        onClick={() => navigate("/")}
        className="navLogo header-logo"
        src={NavLogo}
        alt="Aquaclture"
      />
      <div>
        <Tooltip title="Dashboard">
          <img
            onClick={() => navigate("/")}
            className={
              (location?.pathname === APP_ROUTES_FOR_NAVIGATION.DASHBOARD
                ? selectedStyle
                : "") + " navLogo"
            }
            src={DashboardLogo}
            alt="DashboardLogo"
          />
        </Tooltip>
      </div>
      <Tooltip title="Logout">
        <img
          onClick={userLogout}
          className="navLogo"
          src={LogoutLogo}
          alt="logout"
        />
      </Tooltip>
    </nav>
  );
};

export default SideNavigationBar;
