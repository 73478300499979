import * as S from "./CensorReadingAvgStyles";
import { IAvgCensorReading } from "../../interfaces/app-interfaces";
import CensorStatusBradge from "../../common/censor-status-bradge/CensorStatusBradge";
import CensorIcon from "../../assets/icons/icon.svg";
import React from "react";

const CensorReadingAvg: React.FC<{ censorDetails: IAvgCensorReading }> = ({
  censorDetails,
}) => {
  return (
    <S.CensorReadingCard>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: '0.5rem'
        }}
      >
        <div>
          <S.AvgCardCensorName className="c-fw-5">
            {censorDetails?.type}
          </S.AvgCardCensorName>
          <CensorStatusBradge type={censorDetails.state} />
        </div>
        <img src={CensorIcon} alt="censor-icon" />
      </div>
      <h1 className="l-b-color c-fw-6 c-m-0">
        {censorDetails?.value}{" "}
        <span className="c-fw-4">{censorDetails?.unit}</span>
      </h1>
    </S.CensorReadingCard>
  );
};

export default CensorReadingAvg;
