import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { CColorCodeVariables } from "../../../constants/color.constants";

export const StyledControlPhysicalDeviceDetails = styled(Box)({
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: `rgba(${CColorCodeVariables.paleSilver}, 1)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    borderRadius: '8px',
    '& .header': {
        height: '46px',
        padding: '16px 16px 0px 16px',

        '& .title': {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19.2 px',
            color: `rgba(${CColorCodeVariables.shadowSlate}, 1)`,
            display: 'block',
            height: '66px',
        },
        '& .sub-title': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16.8px',
            color: `rgba(${CColorCodeVariables.black}, 1)`,
            display: 'block',
            height: '17px'
        }
    },
    '& .content': {
        height: '386px',
        padding: '12px 16px 12px 16px',
        '& .img-wrap': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '15px',
            height: '180px',
            '& .detail-img': {
                height: '160px',
                width: '247px',
            }
        },
        '& .details-wrap': {
            overflowY: 'auto',
            '& .field': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                '& .label': {
                    width: '70px',
                    fontWeight: 400,
                    fontSize: '14px',
                    marginRight: '10px',
                    color: `rgba(${CColorCodeVariables.slateBlue}, 1)`

                },
                '& .para': {
                    fontWeight: 400,
                    fontSize: '14px',
                    color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'calc(100% - 70px)',
                    overflow: 'hidden',
                    height: '32px',
                    borderRadius: '4px',
                    padding: '4px 8px 4px 8px',
                    background: `rgba(${CColorCodeVariables.slateBlue}, 0.1)`,

                },
            }
        }

    }
});
