import React, {FC, useRef} from "react"
import ReactECharts, { EChartsOption } from "echarts-for-react";
import * as S from './StyledControlStatusChart'
import { StyledSectionHeading, StyledSectionSubHeading } from "../../../styled-components/StyledSectionHeading";
import { DATA_ZOOM_CONFIG } from "../../../constants/chart-config.constants";
import { CColorCodeVariables } from "../../../constants/color.constants";
import {QUERY_KEY} from "../../../constants/api.constants";
import {useQuery} from "react-query";
import {getDeviceHistoricalData} from "../../../services/api-service/apiService";

interface ControlStatusChartProps {
  device: any
}

const ControlStatusChart: FC<ControlStatusChartProps> = ({device}) => {
  const lineChart = useRef<ReactECharts>(null);

  useQuery([QUERY_KEY.GET_DEVICE_HISTORICAL_DATA, device],() => getDeviceHistoricalData(device.topic), {
    onSuccess: (data:any) => {
      lineChart?.current?.getEchartsInstance()?.setOption({
        dataset: {
          source: data.data.data
        }
      });
    },
    refetchInterval: 10000
  });

  const options: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    dataset: {
      source: [],
      dimensions: ['timestamp', 'power', 'switchStatus']
    },
    grid: {
      show: true,
      borderColor: 'transparent',
      backgroundColor: `rgba(${CColorCodeVariables.white}, 0.8)` // Set the background color of the chart area
    },
    color: [`rgba(${CColorCodeVariables.slateBlue}, 0.8)`, {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        { offset: 0, color: `rgba(${CColorCodeVariables.turquoiseOasis}, 1)` },
        { offset: 1, color: `rgba(${CColorCodeVariables.aquaBlue}, 1)` }
      ], // Change the color below the line
    }],
    legend: {
      icon: 'roundRect',
      itemHeight: '2',
      textStyle: {
        color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
        fontFamily: 'Barlow',
        fontSize: '14px',
      },
      data: [
        { name: 'Physical Switch' },
        { name: 'Intelligent Control' }
      ]
    },
    xAxis: {
      type: 'time',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
    },
    yAxis: {
      type: 'category',
      position: 'left',
      data: ["No Data", "Offline", "Online", "OFF", "ON"]
    },
    dataZoom: DATA_ZOOM_CONFIG,
    series: [
      {
        name: 'Physical Switch',
        type: 'line',
        step: 'end',
        encode: {
          x: 'timestamp',
          y: 'switchStatus'
        }
      },
      {
        name: 'Intelligent Control',
        type: 'line',
        step: 'end',
        encode: {
          x: 'timestamp',
          y: 'power'
        },
      },
    ]
  };

  return <S.StyledControlStatusChart>
    <div style={{ padding: '16px 16px 0 32px' }} >
      <StyledSectionHeading>{device.name} Switch Data</StyledSectionHeading>
      <StyledSectionSubHeading>Switch Trends</StyledSectionSubHeading>
    </div>

    <div>
      <ReactECharts notMerge={true} ref={lineChart} option={options} style={{ height: "410px", width: '100%' }} />
    </div>
  </S.StyledControlStatusChart>
}

export default ControlStatusChart