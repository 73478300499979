import React from "react";
import "./FishProgressStyles.scss";
import { CColorCodeVariables } from "../../constants/color.constants";

// const circularWidth = 200;
const radius = 90;
export const circleProgressDashArray = radius * Math.PI * 2;

const FishProgress: React.FC<{ growth: number }> = ({ growth }) => {
  const dashOffset = circleProgressDashArray - (circleProgressDashArray * growth) / 100;
  return (
    <div className="fish-progress-container" >
      <svg width="291" height="298" viewBox="0 0 291 298" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_di_77_70787)">
          <circle cx="146.5" cy="121.5" r="105" fill="url(#paint0_linear_77_70787)" shapeRendering="crispEdges" />
          <circle cx="146.5" cy="121.5" r="105.5" stroke="url(#paint1_linear_77_70787)" shapeRendering="crispEdges" />
          <circle
            cx={146.5}
            cy={121.5}
            strokeWidth="10px"
            r={90}
            data-testid="growth-circle-progress"
            className="circle-progress"
            style={{
              strokeDasharray: circleProgressDashArray,
              strokeDashoffset: dashOffset,
            }}
            stroke="url(#paint4_linear_77_70787)"
            transform={`rotate(-90 146.5 121.5)`}
          ></circle>
        </g>
        <g filter="url(#filter1_ddi_77_70787)">
          <circle cx="145.5" cy="121.5" r="85" fill="url(#paint2_linear_77_70787)" />
          <circle cx="145.5" cy="121.5" r="84.5" stroke="url(#paint3_linear_77_70787)" />
        </g>
        <g filter="url(#filter2_di_77_70787)">
          <circle cx="146.5" cy="121.5" r="74" fill="url(#paint4_linear_77_70787)" />
        </g>
        <g filter="url(#filter3_di_77_70787)">
          <path d="M150.251 102.934C158.748 102.934 170.339 112.875 170.768 124.487C170.768 124.487 170.556 117.231 170.768 111.843C170.98 106.454 173.742 103.417 181.964 102.724C182.797 102.653 182.973 102.871 183 103.553V115.366C183 118.682 179.952 124.313 173.052 125.313C178.75 125.533 183 130.701 183 135.054V147.073C182.931 147.597 182.766 147.79 182.171 147.902C174.825 147.923 170.768 143.343 170.768 138.784V125.941C170.346 137.454 159.997 147.698 150.251 147.698H146.94C149.424 149.006 149.871 150.123 149.841 152.462C149.635 152.798 149.495 152.863 149.22 152.876C149.22 152.876 145.904 153.083 144.039 152.876C142.174 152.669 140.233 150.923 139.48 147.698H137.402C123.829 147.698 115.743 140.054 114.079 129.665C112.112 129.743 111.139 128.697 111.087 127.178C111.037 125.693 111.734 124.483 113.777 124.487C111.887 123.83 110.652 122.637 111.087 120.961C111.485 119.431 112.766 118.465 114.611 118.966C117.222 109.441 125.648 102.934 137.268 102.934C137.268 102.934 136.993 99.4114 138.858 95.0593C140.723 90.7072 144.246 90.4996 146.111 90.5C147.976 90.5004 161.032 90.5 161.032 90.5C161.764 90.5267 162.067 90.6701 162.276 91.329C162.255 99.09 160.023 101.909 150.251 102.934Z" fill="white" />
        </g>
        <circle cx="127.922" cy="120.27" r="2" fill="#402A39" />
        <g filter="url(#filter4_di_77_70787)">
        </g>
        <g className="fish-growth-container" filter="url(#filter5_bd_77_70787)">
          <path d="M176.5 142.5C176.5 140.291 178.291 138.5 180.5 138.5H259.5C261.709 138.5 263.5 140.291 263.5 142.5V157.5C263.5 159.709 261.709 161.5 259.5 161.5H180.5C178.291 161.5 176.5 159.709 176.5 157.5V142.5Z" fill="#F2F2F2" fillOpacity="0.4" shapeRendering="crispEdges" />
          <text x="180" y="155" fill={`rgba(${CColorCodeVariables.graphiteGray}, 1)`}>Maturity, {growth}%</text>
        </g>
        <defs>
          <filter id="filter0_di_77_70787" x="10.5" y="0.5" width="272" height="272" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="15" />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.607843 0 0 0 0 0.682353 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_77_70787" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_77_70787" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_innerShadow_77_70787" />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.254902 0 0 0 0 0.270588 0 0 0 0 0.364706 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_77_70787" />
          </filter>
          <filter id="filter1_ddi_77_70787" x="0.5" y="7.5" width="290" height="290" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="31" />
            <feGaussianBlur stdDeviation="30" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_77_70787" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.607843 0 0 0 0 0.682353 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_77_70787" result="effect2_dropShadow_77_70787" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_77_70787" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="3" dy="3" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="shape" result="effect3_innerShadow_77_70787" />
          </filter>
          <filter id="filter2_di_77_70787" x="71.5" y="39.5" width="150" height="157" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_77_70787" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_77_70787" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-8" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_77_70787" />
          </filter>
          <filter id="filter3_di_77_70787" x="81" y="75.5" width="132" height="122.468" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="15" />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.607843 0 0 0 0 0.682353 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_77_70787" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_77_70787" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_innerShadow_77_70787" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.254902 0 0 0 0 0.270588 0 0 0 0 0.364706 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_77_70787" />
          </filter>
          <filter id="filter4_di_77_70787" x="66.2729" y="19.5815" width="174.227" height="196.918" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_77_70787" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_77_70787" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-8" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_77_70787" />
          </filter>
          <filter id="filter5_bd_77_70787" x="126.5" y="88.5" width="187" height="123" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_77_70787" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="effect1_backgroundBlur_77_70787" result="effect2_dropShadow_77_70787" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_77_70787" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_77_70787" x1="110.783" y1="25.1066" x2="196.848" y2="213.16" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DEE2E7" />
            <stop offset="1" stopColor="#DBE0E7" />
          </linearGradient>
          <linearGradient id="paint1_linear_77_70787" x1="81.0902" y1="43.1803" x2="191.684" y2="211.008" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.5" />
            <stop offset="1" stopColor="#F6F1F1" />
          </linearGradient>
          <linearGradient id="paint2_linear_77_70787" x1="108.409" y1="45" x2="184.136" y2="198" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F5F5F9" />
            <stop offset="1" stopColor="#E4E8EE" />
          </linearGradient>
          <linearGradient id="paint3_linear_77_70787" x1="99.1364" y1="63.5455" x2="188" y2="193.364" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="#C0C5CD" />
          </linearGradient>
          <linearGradient id="paint4_linear_77_70787" x1="78.605" y1="642.864" x2="257.704" y2="626.724" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00B09B" />
            <stop offset="0.971354" stopColor="#3DB0C9" />
          </linearGradient>
          <linearGradient id="paint5_linear_77_70787" x1="59.255" y1="783.773" x2="286.76" y2="763.271" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00B09B" />
            <stop offset="0.971354" stopColor="#3DB0C9" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default FishProgress;
