import styled from "@emotion/styled";
import { CColorCodeVariables } from "../../constants/color.constants";

export const ControlStatusWarpperStyle = styled("section")({
  backgroundColor: "#FFFFFF",
  padding: "0.2rem 1rem 0.5rem",
  borderRadius: "0.3rem",
  flex: "1 1 10rem",
  maxWidth: "20rem",
  color: `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
  border: '1px solid transparent',
  boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.4)',
  transition: 'border-color 0.5s ease',
  cursor: 'pointer',
    '&:hover': {
        borderColor: `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
    }
});

export const ControlStatusNameStyle = styled("h1")({
  fontWeight: "600",
  margin: "0",
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '0rem',
  color: `rgba(${CColorCodeVariables.slateBlue}, 1)`
});

export const ControlStatusSwitchLabelWrapper = styled('p')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.2rem',
  color: `rgba(${CColorCodeVariables.slateGray}, 0.6)`,
  alignItems: 'center',
  gap: '0.8rem',
  fontSize: '0.85rem'
})
