import { Box, styled } from '@mui/material';
import { CNotificationAlertProperties, ENotificationAlertTypes } from '../../constants/service.contants';
import { WINDOW_INNER_WIDTH } from '../../constants/themes.constants';
import { CColorCodeVariables } from '../../constants/color.constants';

export const StyledNotificationAlert = styled(Box)({
    height: 'auto',
    maxHeight: '100%',
    width: 'auto',
    zIndex: 999,
    position: 'absolute',
    top: 0,
    right: '15px',
    margin: 0,
    padding: '30px 10px 0px',
    overflowX: 'hidden',
    overflowY: 'auto',
    boxSizing: 'border-box',
    alignItem: 'center !important',
    '::-webkit-scrollbar': {
        display: 'none'
    },
    '& .toast-message-wrap': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    '& .notification-message-wrap': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },

    [`@media screen and (max-width: ${WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED}px)`]: {
        right: '0px'
    }
});

const style1 = {

    '& .close-btn-container': {
        height: '16px',
        width: '16px',
        background: `rgba(${CColorCodeVariables.white}, 0.8)`,
        boxShadow: `0px 1px 4px 0px rgba(${CColorCodeVariables.black}, 0.25)`,
        borderRadius: '50%',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        padding: '6px',
        position: 'absolute',
        top: '-14px',
        right: '18px',
        cursor: 'pointer',
        '& .close-icon': {
            padding: '4px',
            height: '8px',
            width: '8px',
        },
        '& .close-text': {
            display: 'none'
        },
        '&:hover': {
            width: 'fit-content',
            padding: '6px 14px',
            borderRadius: '100px',
            background: `rgba(${CColorCodeVariables.white})`,
            zIndex: 1,
            '& .close-icon': {
                display: 'none'
            },
            '& .close-text': {
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14.4px',
                color: `rgba(${CColorCodeVariables.charcoalGray}, 0.6)`,
                display: 'block',
            },
        }
    },
    '& .collapse-expand-container': {
        height: '16px',
        width: '16px',
        background: `rgba(${CColorCodeVariables.white}, 0.8)`,
        boxShadow: `0px 1px 4px 0px rgba(${CColorCodeVariables.black}, 0.25)`,
        borderRadius: '50%',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        padding: '6px',
        position: 'absolute',
        top: '-14px',
        right: '60px',
        cursor: 'pointer',
        '& .arrow-down-icon': {
            padding: '4px',
            height: '16px',
            width: '16px',
            position: 'relative',
            top: '-5px',
            transform: 'rotate(180deg)',
            '&.collapsed': {
                transform: 'rotate(0deg)'
            }
        },
    }
}

const style2 = {
    '& .right-panel': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '90px',
        textAlign: 'right',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14.4px',
        color: `rgba(${CColorCodeVariables.charcoalGray}, 0.6)`,
        '& .date-time': {
            marginTop: '2px',
        },
        '& .total-notification': {

        }
    }
}

const style3 = {
    '& .title': {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19.2px',
        color: `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
    },
    '& .date-time': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14.4px',
        color: `rgba(${CColorCodeVariables.charcoalGray}, 0.6)`
    }
}

const style4 = {
    '& .content': {
        marginTop: '5px',
        '& .para': {
            fontWeight: 400,
            fontSize: '14px',
            color: `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
            lineHeight: '16.8px',
            wordBreak: 'break-all',
            '& .milli-gram': {
                fontWeight: 600,
                color: `rgba(${CColorCodeVariables.crimsonRed}, 1)`
            }
        },
    }
}

export const StyledToastMessageContent = styled(Box)<{ alerttype: ENotificationAlertTypes }>(({ theme, alerttype }) => ({
    height: 'auto',
    width: '440px',
    marginBottom: '22px',
    padding: '16px',
    borderRadius: '12px',
    background: `rgba(${CColorCodeVariables.white}, 0.87)`,
    boxShadow: `0px 2px 4px 0px rgba(${CColorCodeVariables.black}, 0.25)`,
    position: 'relative',

    [`@media screen and (max-width: ${WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED}px)`]: {
        width: '90%'
    },

    '& .toast-alert-container': {
        padding: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        '& .left-panel': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginRight: '12px',
            '& .danger-icon-wrap': {
                height: '40px',
                width: '40px',
                borderRadius: '8px',
                background: CNotificationAlertProperties[alerttype].backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        '& .center-panel': {
            width: '100%',
            '& .header': {
                display: 'flex',
                justifyContent: 'space-between',
                ...style3
            },
            ...style4
        },
        ...style2
    },
    ...style1
}));

export const StyledNotificationMessageContent = styled(Box)<{ alerttype: ENotificationAlertTypes, iscollapsed: string }>(({ theme, alerttype, iscollapsed }) => ({
    height: 'auto',
    width: '440px',
    marginBottom: '22px',
    padding: '16px',
    borderRadius: '12px',
    background: `rgba(${CColorCodeVariables.white}, 0.87)`,
    boxShadow: `0px 2px 4px 0px rgba(${CColorCodeVariables.black}, 0.25)`,
    position: 'relative',

    [`@media screen and (max-width: ${WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED}px)`]: {
        width: '90%'
    },
    ...(iscollapsed === 'true' ? {
        background: `rgba(${CColorCodeVariables.white})`,
        '&:first-of-type': {
            marginTop: '0px',
            zIndex: 9,
            width: '440px',
            [`@media screen and (max-width: ${WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED}px)`]: {
                width: '90%'
            },
        },
        '&:nth-of-type(2)': {
            marginTop: '-92px',
            zIndex: 8,
            width: '420px',
            height: '50px',
            [`@media screen and (max-width: ${WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED}px)`]: {
                width: '85%'
            },
            '.notification-alert-container': {
                display: 'none'
            }
        },
        '&:nth-of-type(3)': {
            marginTop: '-92px',
            zIndex: 7,
            width: '400px',
            [`@media screen and (max-width: ${WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED}px)`]: {
                width: '80%'
            },
            height: '50px',
            '.notification-alert-container': {
                display: 'none'
            }
        },
        '&:nth-of-type(n+4)': {
            display: 'none'
        },
    } : {}),
    '& .notification-alert-container': {
        padding: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        '& .left-panel': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginRight: '12px',
            '& .danger-icon-wrap': {
                height: '40px',
                width: '40px',
                borderRadius: '8px',
                background: CNotificationAlertProperties[alerttype].backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        '& .center-panel': {
            width: '100%',
            '& .header': {
                ...style3
            },
            ...style4
        },
        ...style2
    },
    ...style1
}));
