import axios, { AxiosRequestConfig } from 'axios';
import { Location, NavigateFunction } from 'react-router-dom';
import apiConfig from '../../configurations/api-config';
import { getLocalStorageValue, setLocalStorageValue } from '../local-storage-service/localStorage';
import { CLocalStorageItems } from '../../constants/service.contants';
import { AppDispatch } from '../../store/store';
import { clearUserDetails } from '../../store/slices/userSlice';

//new axios
const axiosInstance = axios.create({
  baseURL: apiConfig.API_DOMAIN,
});

const setupInterceptors = (navigate: NavigateFunction, location: Location, dispatch: AppDispatch) => {
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig | any) => {
    const accessToken: string | null = getLocalStorageValue(CLocalStorageItems.accessToken);
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        withCredentials: true,
      };
    }
    return config;
  },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
      const { pathname } = location;
      if (!error.response) {
        return Promise.reject(error);
      }
      if (error.response.status === 400) {
        return Promise.reject(error.response);
      }
      if (error.response.status === 404) {
        if (error.response.data.message === 'User not found!') {
          return Promise.reject(error.response);
        }
        navigate('/404');
        return Promise.reject(error.response);
      }
      if (error.response.status === 403) {
        const pathList = ['/waiting-approval', '/', '/login'];
        if (!pathList.includes(pathname)) {
          // toast.error(`You are not authorized to perform this page`, {
          //   toastId: 1,
          //   autoClose: 10000,
          // });
        }
        return Promise.reject(error.response);
      }
      if (error.response.status === 401) {
          const theme = getLocalStorageValue('theme')
          localStorage.clear();
          setLocalStorageValue('theme', theme)
          navigate('/login');
          dispatch(clearUserDetails())
          const changePassword = '/change-password/'
          if (!pathname.includes(changePassword)) {
            navigate('/login');
          }
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    }
  );
};

export const getDeviceStatusById = (id: string) => {
  return sendGetRequest(`/mqtt/last-device-status-by-id/${id}`);
}

export const sendCommandToDevice = (data:any) => {
  return sendPostRequest('/mqtt/device/command', data);
}

export const cctvKeepAlive = (farmId:any) => {
  return sendGetRequest(`/mqtt/cctv-keep-alive/${farmId}`);
}

export const updateDeviceTimer = (data:any) => {
  return sendPatchRequest('/mqtt/device-timer', data);
}

export const getDeviceTimer = (deviceId: string) => {
  return sendGetRequest(`/mqtt/device-timer/${deviceId}`);
}

export const getDeviceHistoricalData = (topic:string, chunk?:string) => {
  let endpoint = `/mqtt/historical-data/${topic}`;
  if (chunk) {
    endpoint += `/${chunk}`;
  }
  return sendGetRequest(endpoint);
}

export const getFarmDetails = (id: string) => {
  return sendGetRequest(`/farm/${id}`);
}

export const getFarmControlStatus = (farmId: string) => {
  return sendGetRequest(`/mqtt/device-status-by-farm/${farmId}`);
}

export const getSensorHistoricalData = (deviceId: string, chunk?:string) => {
  let endpoint = `/mqtt/sensor-historical-data/${deviceId}`;
  if (chunk) {
    endpoint += `/${chunk}`;
  }
  return sendGetRequest(endpoint);
}

export const getSensorAverageData = (farmId:string) => {
  return sendGetRequest(`/mqtt/latest-overall-water-quality-data/${farmId}`);
}

export const sendGetRequest = (url: string, params?: any) => axiosInstance.get(url, { params });

export const sendPatchRequest = (url: string, params?: any) => axiosInstance.patch(url, params);

export const sendPostRequest = (url: string, params?: any, headers?: any) => axiosInstance.post(url, params, headers);

export const sendDeleteRequest = (url: string, params?: any) => axiosInstance.delete(url, params);

export default setupInterceptors;
