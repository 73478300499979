interface ClockSvgIconProps {
    height?: number;
    width?: number;
    color?: string;
}

const ClockSvgIcon: React.FC<ClockSvgIconProps> = ({ height = 16, width = 16, color = "white", ...rest }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6668 8.00016C14.6668 11.6802 11.6802 14.6668 8.00016 14.6668C4.32016 14.6668 1.3335 11.6802 1.3335 8.00016C1.3335 4.32016 4.32016 1.3335 8.00016 1.3335C11.6802 1.3335 14.6668 4.32016 14.6668 8.00016Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.4734 10.1202L8.40675 8.88684C8.04675 8.6735 7.75342 8.16017 7.75342 7.74017V5.00684" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ClockSvgIcon;