import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
export interface INotificationState {
    notificationMessageList: any[],
    toastMessageList: any[],
    toastMessageIndex: number,
    toastDelayIndex: any,
    toastTimeout: number,
    isNotificationCollapsed: boolean
}

const initialState: INotificationState = {
    notificationMessageList: [],
    toastMessageList: [],
    toastMessageIndex: 0,
    toastDelayIndex: null,
    toastTimeout: 3000,
    isNotificationCollapsed: false,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotificationMessages(state: INotificationState, action: PayloadAction<{
            alertType: string,
            title: string,
            dateTimeInFormat: string,
            messageContent: string,
        }>) {
            const currentNotificationMessageList: any = [...current(state).notificationMessageList];
            const notificationMessage: string = action.payload.messageContent;
            const indexValue: number = currentNotificationMessageList?.length || 0;
            state.notificationMessageList.push({
                alertType: action.payload.alertType,
                title: action.payload.title,
                dateTimeInFormat: action.payload.dateTimeInFormat,
                indexValue: indexValue,
                message: notificationMessage,
            });
        },
        setToastMessages(state: INotificationState, action: PayloadAction<{
            alertType: string,
            title: string,
            dateTimeInFormat: string,
            messageContent: string,
            timeout: number
        }>) {
            const currentToastMessageList = [...current(state).toastMessageList];
            const currentToastMessageIndex = state.toastMessageIndex;
            const toastMessage = action.payload.messageContent;
            const indexValue = currentToastMessageList.length ? currentToastMessageIndex + 1 : 0;
            state.toastDelayIndex = indexValue;
            state.toastTimeout = action.payload.timeout;

            const newToastMessage = {
                alertType: action.payload.alertType,
                title: action.payload.title,
                dateTimeInFormat: action.payload.dateTimeInFormat,
                indexValue: indexValue,
                message: toastMessage,
            };

            state.toastMessageList = [...currentToastMessageList, newToastMessage];
            state.toastMessageIndex = indexValue;
        },
        delayRemoveToastMessages(state: INotificationState, action: PayloadAction<{
            indexToRemove: number,
        }>) {
            const currentToastMessageListX = [...current(state).toastMessageList];
            if (currentToastMessageListX.length) {
                const toasts = currentToastMessageListX.filter(ele => ele.indexValue !== action.payload.indexToRemove);
                state.toastMessageList = [...toasts];
            } else {
                state.toastMessageIndex = 0;
                state.toastDelayIndex = null;
            }
        },
        removeSingleNotificationMessage(state: INotificationState, action: PayloadAction<{
            indexToRemove: number,
        }>) {
            let currentNotificationMessageList: any = [...current(state).notificationMessageList];
            let indexToRemove = action.payload.indexToRemove;

            const index = currentNotificationMessageList.findIndex((item: any) => item?.indexValue === indexToRemove);

            if (index !== -1) {
                currentNotificationMessageList.splice(index, 1);
                currentNotificationMessageList = currentNotificationMessageList.map((item: any, i: number) => {
                    return { ...item, indexValue: i };
                });
            }
            state.notificationMessageList = [...currentNotificationMessageList];

        },
        removeSingleToastMessage(state: INotificationState, action: PayloadAction<{
            indexToRemove: number
        }>) {
            const currentToastMessageList: any = [...current(state).toastMessageList];
            const indexToRemove = action.payload.indexToRemove;
            const toasts: any[] = currentToastMessageList?.filter((ele: any) => ele?.indexValue !== indexToRemove);
            state.toastMessageList = [...toasts];
            if (!toasts.length) state.toastMessageIndex = 0;
        },
        removeAllNotificationMessage(state: INotificationState) {
            state.notificationMessageList = [];
            state.isNotificationCollapsed = false;
        },
        changeNotificationCollapsedState(state: INotificationState) {
            let currentIsNotificationCollapsed: any = current(state).isNotificationCollapsed;
            state.isNotificationCollapsed = !currentIsNotificationCollapsed;
        }
    }
});

export const {
    setNotificationMessages,
    setToastMessages,
    delayRemoveToastMessages,
    removeSingleNotificationMessage,
    removeSingleToastMessage,
    removeAllNotificationMessage,
    changeNotificationCollapsedState
} = notificationSlice.actions;

export const reducer = notificationSlice.reducer;

export default notificationSlice;