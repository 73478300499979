import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { CColorCodeVariables } from "../../../constants/color.constants";

export const StyledFeederScheduleOverview = styled(Box)({
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: `rgba(${CColorCodeVariables.paleSilver}, 1)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    borderRadius: '8px',
    '& .header': {
        height: 'auto',
        padding: '16px 16px 0px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        '& .title': {

        },
        '& .controls': {
            display: 'flex',
            justifyContent: 'space-between',
        }
    },
    '& .content': {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: '12px 16px 0px 16px',
    }
});
