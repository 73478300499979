import { ShowChartRounded } from "@mui/icons-material";
import { Button, IconButton, styled } from "@mui/material";
import { CColorCodeVariables } from "../../../constants/color.constants";

export const IntakeNavWrapper = styled("nav")({
  maxWidth: "400px",
});

export const StyledNavIconButton = styled(IconButton)({
  background: `rgba(${CColorCodeVariables.slateBlue}, 0.1)`,
  borderRadius: "5px",
  '&:hover': {
    background: `rgba(${CColorCodeVariables.slateBlue}, 0.1)`,
  }
});

export const IntakeStyledNavButton = styled(Button)<{
  selectedintake: number;
  tabIndex: number;
}>(({ selectedintake, tabIndex }) => {
  const selected = tabIndex === selectedintake;
  return {
    background: selected
      ? `rgba(${CColorCodeVariables.slateBlue}, 1)`
      : `rgba(${CColorCodeVariables.slateBlue}, 0.1)`,
    color: selected ? "#FFFFFF" : `rgba(${CColorCodeVariables.slateBlue}, 1)`,
    margin: "0.2rem",
    boxShadow: selected ? `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.25)` : "",
    border: '1px solid transparent',
    transition: 'border-color 0.5s ease',
    "&:hover": {
      background: selected
        ? `rgba(${CColorCodeVariables.slateBlue}, 1)`
        : `rgba(${CColorCodeVariables.slateBlue}, 0.1)`,
      borderColor: `rgba(${CColorCodeVariables.slateBlue}, 0.5)`
    },
  };
});

export const StyledChartLineIcon = styled(ShowChartRounded)({
  background: `rgba(${CColorCodeVariables.white}, 1)`,
  color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
  borderRadius: "5px",
});
