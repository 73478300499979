import { TypeDayKeys } from "../interfaces/feeder-schedule-interface";

export const WEEKDAY_LIST: {
    id: number,
    key: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday',
    label: string
}[] = [
    {
      id: 1,
      key: "monday",
      label: "Monday",
    },
    {
      id: 2,
      key: "tuesday",
      label: "Tuesday",
    },
    {
      id: 3,
      key: "wednesday",
      label: "Wednesday",
    },
    {
      id: 4,
      key: "thursday",
      label: "Thursday",
    },
    {
      id: 5,
      key: "friday",
      label: "Friday",
    },
];

export const DAYS: TypeDayKeys[] = ["monday"
, "tuesday"
, "wednesday"
, "thursday"
, "friday"
, "saturday"
, "sunday"]