import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { StyledIntelligentControlSwitch } from "./StyledIntelligentControlSwitch";
import ConfigSwitch from "../../../common/config-switch/ConfigSwitch";
import WifiOnlineIcon from "../../../common/common-svg-icons/WifiOnline";
import ClockSvgIcon from "../../../common/common-svg-icons/ClockSvgIcon";
import { CColorCodeVariables } from "../../../constants/color.constants";
import {getDeviceStatusById} from "../../../services/api-service/apiService";
import {QUERY_KEY} from "../../../constants/api.constants";

interface IntelligentControlSwitchProps {
    indexValue: number;
    controlType: "Aerators" | "Feeders";
    activeControlIndex: number;
    handleControlClick: (index: number) => void;
    device: any;
}

interface SwitchProps {
    status: string,
    time: Date | null | string,
    checked: boolean
}

interface DeviceStatusProps {
    physicalSwitch: SwitchProps,
    smartSwitch: SwitchProps,
    deviceConnection: string
}

const IntelligentControlSwitch: FC<IntelligentControlSwitchProps> = ({ indexValue, controlType, activeControlIndex, handleControlClick, device }) => {
    const [isActive, setIsActive] = useState<boolean>(indexValue === activeControlIndex);
    const [deviceStatus, setDeviceStatus] = useState<DeviceStatusProps>({
        physicalSwitch: {
            status: '',
            time: null,
            checked: false
        },
        smartSwitch: {
            status: '',
            time: null,
            checked: false
        },
        deviceConnection: ''
    });

    useEffect(() => {
        setIsActive(indexValue === activeControlIndex);
    }, [activeControlIndex, setIsActive, indexValue])

    useQuery([QUERY_KEY.GET_DEVICE_STATUS_BY_ID, device.deviceId], () => getDeviceStatusById(device.deviceId),
      {
          onSuccess: (data:any) => {
              const temp = {
                  physicalSwitch: {
                      status: data.data.info1.physicalSwitch,
                      time: data.data.info1.time ? new Date(data.data.info1.time) : 'No Data',
                      checked: data.data.info1.physicalSwitch ? data.data.info1.physicalSwitch !== "OFF" : false
                  },
                  smartSwitch: {
                      status: data.data.info2.devicePower,
                      time: data.data.info2.time ? new Date(data.data.info2.time) : 'No Data',
                      checked: data.data.info2.devicePower ? data.data.info2.devicePower !== "OFF" : false
                  },
                  deviceConnection: data.data.info3.connectionStatus
              }
              setDeviceStatus(temp);
          },
          refetchInterval: 5000
      });

    return (
        <StyledIntelligentControlSwitch isactive={isActive.toString()}
            onClick={() => { handleControlClick(indexValue) }}>
            <div className="header">
                <div className="title-wrap">
                    <label className="title">{device.name}</label>
                </div>
                <div className="img-indicator">
                    <WifiOnlineIcon width={24} height={27} color={deviceStatus.deviceConnection === 'Online' ? `rgba(${CColorCodeVariables.charcoalGray}, 1)` : `rgba(${CColorCodeVariables.red}, 1)`} />
                </div>
            </div>
            <div className="content">
                <div className="para-row">
                    <label className="label-col">Intelligent Control</label>
                    <span className="status-col">
                        <ConfigSwitch name={"intelligentControl" + indexValue} deviceId={device.deviceId} deviceTopic={device.topic} checked={deviceStatus.smartSwitch.checked} isActiveSwitch={isActive} disabled={!isActive} type={controlType} />
                    </span>
                </div>
                <div className="footer">
                    <div className="history">
                    <span className="clock">
                        <span className="clock-icon">
                            <ClockSvgIcon color={isActive ? `rgba(${CColorCodeVariables.white}, 1)` : `rgba(${CColorCodeVariables.charcoalGray}, 1)`} />
                        </span>
                    </span>
                        <span className="date-time">Last Updated {deviceStatus?.smartSwitch.time?.toLocaleString()}</span>
                    </div>
                </div>
            </div>
            <br></br>
            <div className="content">
                <div className="para-row">
                    <label className="label-col">Physical</label>
                    <span className="status-col">
                        <ConfigSwitch name={"physical" + indexValue} deviceId={device.deviceId} checked={deviceStatus.physicalSwitch.checked} isActiveSwitch={isActive} disabled={true} type={controlType} />
                    </span>
                </div>
                <div className="footer">
                    <div className="history">
                    <span className="clock">
                        <span className="clock-icon">
                            <ClockSvgIcon color={isActive ? `rgba(${CColorCodeVariables.white}, 1)` : `rgba(${CColorCodeVariables.charcoalGray}, 1)`} />
                        </span>
                    </span>
                        <span className="date-time">Last Updated {deviceStatus?.physicalSwitch.time?.toLocaleString()}</span>
                    </div>
                </div>
            </div>

        </StyledIntelligentControlSwitch>
    )
}

export default IntelligentControlSwitch;