import { styled } from '@mui/material';

export const FishOverviewWrapperStyle = styled('section')(({ theme }) => ({
    backgroundColor: theme?.palette?.background?.default,
    marginLeft: '1rem',
    borderRadius: '0.3rem',
    padding: '1rem',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
        // Styles applied for screens equal to or wider than 600px (sm breakpoint)
        marginLeft: '0rem',
      },
    
}))