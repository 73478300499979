import { styled } from "@mui/material";
import { CColorCodeVariables } from "../../constants/color.constants";

export const ReadingAndControlWarapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette?.background?.default,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1rem',
    marginBottom: '0.8rem',
    borderRadius: '8px',
    gap: '1rem',
    justifyContent: 'space-around',
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`
}))

export const ReadingAndControlLabelCard = styled('section')({
    padding: '0.5rem 0.5rem 0rem 1rem', 
    display: 'flex', 
    flexDirection: 'column', 
    flex: '1 1 100px'
})