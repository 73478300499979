import {FC, useState} from "react";
import {StyledFeederScheduleOverview} from "./StyledFeederScheduleOverview";
import {StyledSectionHeading, StyledSectionSubHeading} from "../../../styled-components/StyledSectionHeading";
import {Button, Grid} from "@mui/material";
import {CColorCodeVariables} from "../../../constants/color.constants";
import FeederScheduleForm from "./feeder-schedule-form/FeederScheduleForm";
import FeederScheduleList from "./feeder-schedule-list/FeederScheduleList";
import {IScheduleForm} from "../../../interfaces/feeder-schedule-interface";
import {addOneMinuteTime, formatTime_HHMM} from "../../../common/utils/dateUtils";
import {getDeviceTimer, updateDeviceTimer} from "../../../services/api-service/apiService";
import {useMutation, useQuery} from "react-query";
import {QUERY_KEY} from "../../../constants/api.constants";
import ConfigSwitch from "../../../common/config-switch/ConfigSwitch";

interface FeederScheduleOverviewProps {
    activeControlIndex: number;
    device: any
}

const FeederScheduleOverview: FC<FeederScheduleOverviewProps> = ({ activeControlIndex, device }) => {
    const [adding, setAdding] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState<number>(0)
    const date = new Date();
    const defaultState = {
        startTime: formatTime_HHMM(date),
        endTime: formatTime_HHMM(addOneMinuteTime(date)),
        duration: 1,
        frequencies: 0,
        daily: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    };
    const [scheduleList, setScheduleList] = useState<IScheduleForm[]>([defaultState,defaultState,defaultState,defaultState,defaultState,defaultState,defaultState,defaultState]);
    const [timerEnabled, setTimerEnabled] = useState<boolean>(false);
    const handleScheduleAddToList = (schedule: IScheduleForm) => {
        const newScheduleList = [...scheduleList];
        newScheduleList[selectedSchedule] = schedule;
        setScheduleList(newScheduleList);
        updateScheduleMutation.mutate({
            deviceId: device.deviceId,
            schedule: newScheduleList
        });
    };

    const handleEnableTimer = (isEnabled: boolean) => {
        setTimerEnabled(isEnabled);
        updateScheduleMutation.mutate({
            deviceId: device.deviceId,
            enable: isEnabled
        })
    }

    useQuery([QUERY_KEY.GET_DEVICE_SCHEDULE, device], ()=> getDeviceTimer(device.deviceId), {
        onSuccess: (res) => {
            if (res.data.schedule) {
                setScheduleList(res.data.schedule);
                if (res.data.hasOwnProperty('enable')) {
                    setTimerEnabled(res.data.enable);
                } else {
                    setTimerEnabled(false);
                }
            } else {
                setScheduleList([defaultState,defaultState,defaultState,defaultState,defaultState,defaultState,defaultState,defaultState]);
                setTimerEnabled(false);
            }
        }
    });

    const handleScheduleAdd = (s: number) => {
        setAdding(true)
        setSelectedSchedule(s)
    }

    const updateScheduleMutation = useMutation(updateDeviceTimer, {
        onSuccess: (res) => {
        },
        onError: (error) => {
        }
    });

    const resetScheduleList = () => {
        setScheduleList([defaultState, defaultState ,defaultState ,defaultState ,defaultState ,defaultState ,defaultState, defaultState]);
    };

    return (
        <StyledFeederScheduleOverview>
            <div className="header">
                <div className="title">
                    <StyledSectionHeading>Schedule Overview</StyledSectionHeading>
                    <StyledSectionSubHeading>Feeder Switch Schedule Overview</StyledSectionSubHeading>

                </div>
                <div className="controls">
                    <Button sx={{
                        height: '22px',
                        width: '64px',
                        borderRadius: '4px',
                        padding: '4px 16px 4px 16px',
                        background: `rgba(${CColorCodeVariables.slateBlue}, 0.1)`,
                        boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.25)`,
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '14.4px',
                        textAlign: 'center',
                        color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
                        marginRight: '8px'
                    }}
                    onClick={() => resetScheduleList()}>Reset</Button>
                    <ConfigSwitch name={device.name} checked={timerEnabled} type={'timer'} handleTimerEnabling={handleEnableTimer} />
                </div>
            </div>

            <div className="content">
                <Grid container sx={{ height: '100%', width: '100%' }}>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12} sx={{ height: '230px', marginBottom: '12px' }}>
                        <FeederScheduleList selectedSchedule={selectedSchedule} handleScheduleAdd={handleScheduleAdd}
                                            setSelectSchedule={setSelectedSchedule} scheduleList={scheduleList}/>
                    </Grid>
                    <Grid item xl={9} lg={9} md={9} sm={12} xs={12} sx={{ height: '230px', marginBottom: '12px' }}>
                        <FeederScheduleForm handleScheduleAddToList={handleScheduleAddToList} adding={adding} zeroSchedules={!scheduleList.length} schedule={scheduleList[selectedSchedule]} />
                    </Grid>
                </Grid>
            </div>
        </StyledFeederScheduleOverview>
    )
}

export default FeederScheduleOverview;