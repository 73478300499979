import React, { useMemo, useRef } from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import * as S from './CensorReadingChartStyles';
import { useMediaQuery, useTheme } from "@mui/material";
import { DATA_ZOOM_CONFIG } from "../../../constants/chart-config.constants";
import { CColorCodeVariables } from "../../../constants/color.constants";

const mobileGrid = {
  grid: {
    left: '15%', // Increase left margin
    right: '2%', // Increase right margin
    bottom: '15%', // Increase bottom margin
    containLabel: true
  }
}

const MyChart = (props: {data:any, typeCode: string, thresholds: any}) => {

  const lineChart = useRef<ReactECharts>(null)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust the breakpoint as needed
  let data: any = [];

  function convertUtcToLocal(timestamps:any) {
    return timestamps.map((utcString: string | number | Date) => {
      const utcDate = new Date(utcString);
      const offsetMinutes = utcDate.getTimezoneOffset();
      const localDate = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);
      const localISOString = localDate.toISOString();
      return localISOString.slice(0,-5);
    });
  }

  const timestamps = convertUtcToLocal(props?.data.map((entry:any) => entry.timestamp));
  const tempData = props?.data.map((entry:any) => entry.temp);
  const doData = props?.data.map((entry:any) => entry.do);
  const ammData = props?.data.map((entry:any) => entry.amm);
  const phData = props?.data.map((entry:any) => entry.ph);

  function getWhichData() {
    switch (props.typeCode) {
      case 'ph':
        return phData;
      case 'do':
        return doData;
      case 'amm':
        return ammData;
      case 'temp':
        return tempData;
      default:
        return data;
    }
  }

  const getMarkLines = useMemo(() => {
    const markLines = [
      props.thresholds.lowerCrit ? {
        type: "max",
        name: "Max",
        yAxis: props.thresholds.lowerCrit,
        color: `rgba(${CColorCodeVariables.scarletBlaze}, 0.5)`,
        label: "Critical",
        shortName: 'crtcl'
      } : null,
      props.thresholds.lowerAccept ? {
        type: "average",
        name: "Avg",
        yAxis: props.thresholds.lowerAccept,
        color: `rgba(${CColorCodeVariables.sunburstOrange}, 0.5)`,
        label: "Acceptable",
        shortName: 'accept'
      } : null,
      props.thresholds.optimal ? {
        type: "min",
        name: "Min",
        yAxis: props.thresholds.optimal,
        color: `rgba(${CColorCodeVariables.turquoiseOasis}, 0.5)`,
        label: "Optimal",
        shortName: 'optm'
      } : null,
      props.thresholds.upperAccept ? {
        type: "average",
        name: "Avg",
        yAxis: props.thresholds.upperAccept,
        color: `rgba(${CColorCodeVariables.sunburstOrange}, 0.5)`,
        label: "Acceptable",
        shortName: 'accept'
      } : null,
      props.thresholds.upperCrit ? {
        type: "max",
        name: "Max",
        yAxis: props.thresholds.upperCrit,
        color: `rgba(${CColorCodeVariables.scarletBlaze}, 0.5)`,
        label: "Critical",
        shortName: 'crtcl'
      } : null,
    ];

    return markLines?.map((line) => ({
      type: line?.type,
      name: line?.name,
      yAxis: line?.yAxis,
      lineStyle: { color: line?.color, type: "solid" },
      label: {
        position: "start",
        formatter: line?.label,
        fontFamily: "Barlow",
        color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
        fontSize: isMobile ? '10' : '12'
      },
      symbol: "none",
    }));
    // eslint-disable-next-line
  }, [props.thresholds]);

  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      }
    },
    color: [`rgba(${CColorCodeVariables.slateBlue}, 0.5)`],
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: timestamps
    },
    yAxis: {
      type: "value",
      position: "right",
    },
    dataZoom: DATA_ZOOM_CONFIG,
    series: [
      {
        data: getWhichData(),
        type: "line",
        symbol: 'none',
        name: 'Intake 1',
        lineStyle: {
          color: `rgba(${CColorCodeVariables.slateBlue}, 0.5)`
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: `rgba(${CColorCodeVariables.turquoiseOasis}, 0.2)` },
              { offset: 1, color: `rgba(${CColorCodeVariables.aquaBlue}, 0.2)` }
            ], // Change the color below the line
          }
        },
        markLine: {
          data: getMarkLines,
        },
      },
    ],
  };

  if (isMobile) {
    option.grid = mobileGrid.grid
  }

  return (
    <S.CensorReadingChartWrapper>
      <ReactECharts notMerge={true} ref={lineChart} option={option} style={{ height: "400px", width: '100%' }} />
    </S.CensorReadingChartWrapper>
  );
};

export default MyChart;
