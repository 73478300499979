import {useQuery} from "react-query";
import {QUERY_KEY} from "../../constants/api.constants";
import {cctvKeepAlive} from "../../services/api-service/apiService";
import {Button} from "@mui/material";

const KeepAlive = () => {
  useQuery([QUERY_KEY.GET_CCTV_KEEP_ALIVE], () => cctvKeepAlive('65e68bf89ddd939e0830a1aa'), {
    refetchInterval: 5000,
  });

  return (
    <div></div>
  )
}

export const FloatingVideoPlayer = (props: {handleClose:any}) => {

  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', height: '50%', background: 'lightgrey', zIndex: 10}}>
      <Button className="sc-btn" variant={"contained"}
              onClick={props.handleClose} style={{position: 'fixed', left:'50%', top:'5%', transform: 'translate(-50%, -50%)'}}>Close CCTV</Button>
      <KeepAlive/>
      <iframe src={'https://dev-api-aqua.agrimor.com/stream/iprkolam10'} style={{ width: '100%', height: '100%', border: 0 }} title={'cctv'} />
    </div>
  )
}

export default FloatingVideoPlayer;