import { styled } from "@mui/material"; 
import { CColorCodeVariables } from "../../../constants/color.constants";

export const StyledControlStatusChartSkeleton = styled('section')(({ theme }) => ({
    background: `rgba(${CColorCodeVariables.white}, 1)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    borderRadius: '8px',
    marginLeft: '0.8rem',
    height: '440px',
    padding: '16px',
    '& .header': {
        height: '70px',
        '& .title': {
        height: '30px',
            width: '50%',
            background: CColorCodeVariables.skeletonBg
        },
        '& .sub-title': {
        height: '30px',
            width: '30%',
            background: CColorCodeVariables.skeletonBg
        }
    },

    '& .content': {
        height: '400px',
        width: '100%',
        '& .title-wrap': {
            display: 'flex',
            justifyContent: 'space-between',
            height: '40px',
            marginBottom: '10px',
            '& .chart-type': {
                width: '20%',
                height: '50px',
                background: CColorCodeVariables.skeletonBg
            },
            '& .title': {
                width: '40%',
                height: '50px',
                background: CColorCodeVariables.skeletonBg
            }
        },
        '& .chart-wrap': {
            width: '100%',
            height: '280px',
            '& .chart': {
                position: 'relative',
                top: '-85px',
                height: '440px',
                width: '100%',
                background: CColorCodeVariables.skeletonBg

            }
        },
        '& .slider': {
            height: '50px',
            background: CColorCodeVariables.skeletonBg
        }
    },

    [theme?.breakpoints?.down('md')]: {
        marginLeft: '0px'
    }
}));