import FishForm from "../../assets/images/fish-farm.png";
import FarmReadingsAndStatusWarp from "../../components/farm-readings-status-warp/FarmReadingsAndStatusWarp";
import CensorReadingAvg from "../../components/censor-reading-avg/CensorReadingAvg";
import { Button, Grid } from "@mui/material";
import ControlStatus from "../../components/control-status/ControlStatus";
import FishOverview from "../../components/fish-overview/FishOverview";
import IntakeReading from "../../components/intake-reading/IntakeReading";
import * as S from './DashboardPageStyles';
import { useQuery} from "react-query";
import {
  getFarmControlStatus,
  getFarmDetails,
  getSensorAverageData
} from "../../services/api-service/apiService";
import {useState} from "react";
import {QUERY_KEY} from "../../constants/api.constants";
import FloatingVideoPlayer from "../../components/video-player/FloatingVideoPlayer";

export interface ControlProps {
  name: 'Aerators' | 'Feeders',
  intelligentControl: any,
  physicalControl: any,
  connectionStatus: any,
  devices: [...any]
}

const ControlTab = () => {
  const [control, setControl] = useState<any>();

  useQuery([QUERY_KEY.GET_FARM_CONTROL_STATUS], () => getFarmControlStatus('65e68bf89ddd939e0830a1aa'), {
    onSuccess: (data: any) => {
      setControl(data.data);
    },
    refetchInterval: 10000,
  });

  return (
    <FarmReadingsAndStatusWarp
      header="Control Panel"
      subHeader="Switch Configurations"
    >
      {control?.map((control:any) => {
        if (control.name !== 'Intake') {
          return (<ControlStatus controlData={control} key={control.name}/>);
        } else {
          return "";
        }
      })}
    </FarmReadingsAndStatusWarp>
  )
}

const DashboardPage = () => {
  const [farmDetails, setFarmDetails] = useState<any>();
  const [sensorReadingAvg, setSensorReadingAvg] = useState<any>([]);

  useQuery([QUERY_KEY.GET_FARM_DETAILS], ()=> getFarmDetails('65e68bf89ddd939e0830a1aa'), {
    onSuccess: (data: any) => {
      setFarmDetails(data.data);
    }
  });

  useQuery([QUERY_KEY.GET_AVERAGE_SENSOR_READING_DATA, farmDetails], () => getSensorAverageData('65e68bf89ddd939e0830a1aa'), {
    onSuccess: (data: any) => {
      setSensorReadingAvg([
        {
          key: 1,
          value: data.data.ph,
          unit: "Neutral",
          type: "pH Level",
          state: getThresholdName(data.data.ph, 'ph')
        },
        {
          key: 2,
          value: data.data.temp,
          unit: "°C",
          type: "Temperature",
          state: getThresholdName(data.data.temp, 'temp')
        },
        {
          key: 3,
          value: data.data.do,
          unit: "mg/L",
          type: "Dissolved Oxygen",
          state: getThresholdName(data.data.do, 'do')
        },
        {
          key: 4,
          value: data.data.amm,
          unit: "mg/L",
          type: "Ammonia Level",
          state: getThresholdName(data.data.amm, 'amm')
        },
      ]);
    }
  });

  function getThresholdName(value:number, unit:string) {
    if (farmDetails?.waterQualityParams) {
      // @ts-ignore
      for (const range in farmDetails?.waterQualityParams[unit]) {
        // @ts-ignore
        if (value >= farmDetails?.waterQualityParams[unit][range].min && value <= farmDetails?.waterQualityParams[unit][range].max) {
          return range.split('-')[0].toUpperCase();
        }
      }
    }
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
      <div style={{ margin: "1rem" }}>
        {open ? <FloatingVideoPlayer handleClose={handleClose}/> : ''}
        <Grid container>
          <Grid item xs={10}>
            <S.FarmName className="c-fw-7">{farmDetails?.farmName}</S.FarmName>
            <S.FarmAddress>{farmDetails?.farmLocation}</S.FarmAddress>
          </Grid>
          <Grid item xs={2}>
            <Button className="sc-btn" variant={"contained"}
                    onClick={handleOpen}>Open CCTV</Button>
          </Grid>
        </Grid>
        <FarmReadingsAndStatusWarp header="Average Reading" subHeader="Overview">
          {sensorReadingAvg?.map((sensor:any) => (
            <CensorReadingAvg key={sensor?.key} censorDetails={sensor} />
          ))}
        </FarmReadingsAndStatusWarp>
        <Grid container>
          <Grid item lg={8.5} xl={8.5} md={8.5} sm={12} xs={12}>
            <img
              style={{
                width: "100%",
                borderRadius: "0.3rem",
                marginBottom: "0.8rem",
              }}
              src={FishForm}
              alt="Fish Farm"
            />
            <ControlTab/>
          </Grid>
          <Grid item lg={3.5} xl={3.5} md={3.5} sm={12} xs={12}>
            <FishOverview />
          </Grid>
        </Grid>
        <IntakeReading intakeDevices={farmDetails?.farmConfiguration} readingParams={farmDetails?.waterQualityParams}/>
      </div>
  );
};

export default DashboardPage;
