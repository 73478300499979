// import { Button, Paper, Tooltip } from '@mui/material';
import FishProgress from '../fish-progress/FishProgress';
import * as S from './FishOverviewStyles';

const FishOverview = () => {
    return (
        <S.FishOverviewWrapperStyle >
            <h1 className='l-b-color c-fw-6 c-mt-0'>Fish Maturity</h1>
            <div style={{ textAlign: 'center', margin: 'auto' }} >
                    {/* <FishProgress growth={50} /> */}
                    <FishProgress growth={50} />
                <h3 className='c-fw-6 c-mt-0' style={{ color: '#3C4043', marginBottom: '0.2rem' }} >RED HYBRID TILAPIA</h3>
                <p>4 months to be harvested</p>
            </div>

            {/* Upcoming Feature */}
            {/* <section style={{ boxShadow: '0px 0px 1px 0px #00000066', margin: '1rem 0', display: 'flex', justifyContent: 'space-around', 
            background: '#FFFFF' }} >
                <Button sx={{ color: '#FFFFFF', backgroundColor: '#3DB0C9CC', flexGrow: '1', flexBasis: '5rem' }} >Info</Button>
                <Button sx={{ color: '#000000', fontWeight: '400', flexGrow: '1' }} >Schedules</Button>
                <Button sx={{ color: '#000000', fontWeight: '400', flexGrow: '1' }} >Participants</Button>
            </section>

            <Paper  sx={{ boxShadow: '0px 0px 1px 0px #00000040', padding: '1rem', margin: '0.5rem', display: 'flex', justifyContent: 'space-around' }} >
                <div className='l-sh-b-color' style={{ textAlign: 'center' }}>
                    <p className='c-fw-6'>200</p>
                    <p style={{ fontSize: '0.85rem' }} className='c-fw-4'>Average Weight, g</p>
                </div>
                <div className='l-sh-b-color' style={{ textAlign: 'center' }}>
                    <p className='c-fw-6'>2000</p>
                    <p style={{ fontSize: '0.85rem' }}  className='c-fw-4'>Quantity, no</p>
                </div>
                <div className='l-sh-b-color' style={{ textAlign: 'center' }}>
                    <p className='c-fw-6'>3.2</p>
                    <p style={{ fontSize: '0.85rem' }}  className='c-fw-4'>Age, months</p>
                </div>
            </Paper> */}
        </S.FishOverviewWrapperStyle>
    )
}

export default FishOverview