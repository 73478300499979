import React, { FC } from "react";
import { StyledFeederScheduleList } from "./StyledFeederScheduleList";
import {IScheduleForm} from "../../../../interfaces/feeder-schedule-interface";

interface FeederScheduleListProps {
    handleScheduleAdd: (index: number) => void,
    selectedSchedule: number,
    setSelectSchedule:  React.Dispatch<React.SetStateAction<number>>
    scheduleList: IScheduleForm[];
}

const FeederScheduleList: FC<FeederScheduleListProps> = ({ handleScheduleAdd, selectedSchedule, setSelectSchedule, scheduleList}) => {

    return (
        <StyledFeederScheduleList>
            {scheduleList?.length ? scheduleList.map((ele, index) => {
                return (
                    <div className={"schedule-item " + (selectedSchedule === index ? 'selcted-schedule' : '')} onKeyDown={()=>{}} onClick={() => setSelectSchedule(index)} key={'device-schedule-' + index} role={"button"}>
                        <span className="display-name">
                            Schedule {index + 1}
                        </span>
                    </div>
                )
            }) : <></>}
        </StyledFeederScheduleList>
    )
}

export default FeederScheduleList;