import { ReactNode } from "react";
import * as S from "./FarmReadingsAndStatusWarpStyles";
import { StyledSectionHeading, StyledSectionSubHeading } from "../../styled-components/StyledSectionHeading";

const FarmReadingsAndStatusWarp = (props: {
  children: ReactNode;
  header: string;
  subHeader: string;
}) => {
  return (
    <S.ReadingAndControlWarapper>
      <S.ReadingAndControlLabelCard>
        <StyledSectionHeading data-testid='reading-status-header' >{props?.header}</StyledSectionHeading>
        <StyledSectionSubHeading data-testid='reading-status-sub-header' >{props?.subHeader}</StyledSectionSubHeading>
      </S.ReadingAndControlLabelCard>
      {props?.children}
    </S.ReadingAndControlWarapper>
  );
};

export default FarmReadingsAndStatusWarp;
