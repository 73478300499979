import { FC, useEffect, useState } from "react"
import { WINDOW_INNER_WIDTH } from "../../../constants/themes.constants";
import { Skeleton } from "@mui/material";
import { StyledControlPhysicalDeviceDetailsSkeleton } from "./StyledControlPhysicalDeviceDetailsSkeleton";
interface ControlPhysicalDeviceDetailsSkeletonProps {
    controlType: "Aerators" | "Feeders";
}

const ControlPhysicalDeviceDetailsSkeleton: FC<ControlPhysicalDeviceDetailsSkeletonProps> = ({ controlType, ...rest }) => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledControlPhysicalDeviceDetailsSkeleton ismobile={isMobile.toString()}>
            <div className="header">
                <Skeleton className="title" animation="wave" />
                <Skeleton className="sub-title" animation="wave" />
            </div>
            
            <div className="content">
                <div className="img-wrap">
                    <Skeleton className="detail-img" animation="wave" />
                </div>
                <div className="details-wrap">
                    <div className="field">
                        <Skeleton className="label" animation="wave" />
                        <Skeleton className="para" animation="wave"/>
                    </div>

                    <div className="field">
                        <Skeleton className="label" animation="wave" />
                        <Skeleton className="para" animation="wave"/>
                    </div>

                    <div className="field">
                        <Skeleton className="label" animation="wave" />
                        <Skeleton className="para" animation="wave"/>
                    </div>

                    <div className="field">
                        <Skeleton className="label" animation="wave" />
                        <Skeleton className="para" animation="wave" />
                    </div>
                </div>
            </div>

        </StyledControlPhysicalDeviceDetailsSkeleton>
    )
}

export default ControlPhysicalDeviceDetailsSkeleton;