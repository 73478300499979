import { FC } from "react"
import { StyledControlPhysicalDeviceDetails } from "./StyledControlPhysicalDeviceDetails";
import padleWheelImg from '../../../assets/icons/padlewheele-aerator.svg';
import feederImg from '../../../assets/icons/feeder.svg';
import { StyledSectionHeading, StyledSectionSubHeading } from "../../../styled-components/StyledSectionHeading";
interface ControlPhysicalDeviceDetailsProps {
    activeControlIndex: number;
    controlType: "Aerators" | "Feeders";
}

const ControlPhysicalDeviceDetails: FC<ControlPhysicalDeviceDetailsProps> = ({ activeControlIndex, controlType, ...rest }) => {

    return (
        <StyledControlPhysicalDeviceDetails>
            <div className="header">
                <StyledSectionHeading>{controlType.slice(0, -1)} {activeControlIndex + 1} Details</StyledSectionHeading>
                <StyledSectionSubHeading>Technical Specifications</StyledSectionSubHeading>
            </div>
            <div className="content">
                <div className="img-wrap">
                    <img className="detail-img" src={controlType === 'Aerators' ? padleWheelImg : feederImg} alt="padleWheelImg" />
                </div>
                <div className="details-wrap">
                    <div className="field">
                        <label className="label">ID</label>
                        <p className="para">{controlType.slice(0, -1)} {activeControlIndex + 1}</p>
                    </div>
                    <div className="field">
                        <label className="label">Voltage</label>
                        <p className="para">240<span style={{ opacity: '0.6', paddingLeft: '0.2rem' }} > V</span></p>
                    </div>
                    <div className="field">
                        <label className="label">Capacity</label>
                        <p className="para">20<span style={{ opacity: '0.6', paddingLeft: '0.2rem' }}>kg</span></p>
                    </div>
                    <div className="field">
                        <label className="label">Schedule</label>
                        <p className="para">Schedule 1</p>
                    </div>
                </div>
            </div>

        </StyledControlPhysicalDeviceDetails>
    )
}

export default ControlPhysicalDeviceDetails;