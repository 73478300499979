import { styled } from "@mui/material"
import { ReactNode } from "react"
import { CColorCodeVariables } from "../../constants/color.constants"

const ControlStatusSwitchLabelStyle = styled('span')({
    border: `0.5px solid rgba(${CColorCodeVariables.slateBlue}, 0.3)`,
    borderRadius: '4px',
    display: "flex",
    textAlign: 'center',
    gap: '0.5rem',
    justifyContent: 'space-around',
    fontSize: '0.8rem'
})

const SwitchLabelText = styled('span')<{ labelStatus: boolean }>(({ labelStatus }) => ({
    boxShadow: `0px 2px 10px 0px rgba(${CColorCodeVariables.serenityBlue}, 0.12)`,
    borderRadius: '3px',
    color: `rgba(${CColorCodeVariables.white}, 1)`,
    fontWeight: '600',
    padding: '0.2rem 0.4rem',
    minWidth: labelStatus ? '16.5px' : '22.3px',
    background: labelStatus ? `linear-gradient(84.85deg, rgba(${CColorCodeVariables.turquoiseOasis}, 0.8) -21.2%, rgba(${CColorCodeVariables.aquaBlue}, 0.8) 87.5%)` : `rgba(${CColorCodeVariables.slateBlue}, 1)`
}))

const ControlStatusSwitchLabel: React.FC<{ count: number, labelStatus: boolean, labelText: string | ReactNode }> = ({ count, labelStatus, labelText }) => {
    return <ControlStatusSwitchLabelStyle>
        <span style={{ padding: '0.2rem 0.4rem' }} >{count}</span> <SwitchLabelText labelStatus={labelStatus}>{labelText}</SwitchLabelText>
    </ControlStatusSwitchLabelStyle>
}

export default ControlStatusSwitchLabel