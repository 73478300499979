import { useEffect, useRef, useState } from "react";
import * as S from "./TimePickerStyles";

const hours = Array.from({ length: 24 }, (_, i) => ("0" + i).slice(-2));
const minutes = Array.from({ length: 60 }, (_, i) => ("0" + i).slice(-2));

const TimePicker: React.FC<{
  disable?: boolean;
  minHours?: number;
  minMintues?: number;
  value: string;
  handleTimeChange: (time: string) => void;
}> = ({ disable, minHours = 0, minMintues = 0, value, handleTimeChange }) => {
  const [openTimer, setOpenTimer] = useState(false);
  const [selectedHours, setSelectedHours] = useState(value?.split(":")[0]);
  const [selectedMintues, setSelectedMintues] = useState(value?.split(":")[1]);

  const hourRef = useRef<HTMLDivElement>(null);
  const minuteRef = useRef<HTMLDivElement>(null);
  const hourValueRef = useRef<HTMLParagraphElement>(null);
  const timePickerPopup = useRef<HTMLDivElement>(null);
  const timerInputRef = useRef<HTMLInputElement>(null);

  // Add event listener to handle click outside the popup
  const handleClickOutside = (event: MouseEvent) => {
    if (
      timePickerPopup.current &&
      !timePickerPopup.current.contains(event.target as Node) &&
      !timerInputRef?.current?.contains(event?.target as Node)
    ) {
      setOpenTimer(false);
    }
  };

  useEffect(() => {
    // Add event listener when the popup is open
    if (openTimer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove event listener when the popup is closed
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openTimer]);

  const customScroll = (ref: HTMLDivElement, value: string) => {
    const itemHeight = hourValueRef?.current?.clientHeight || 1;
    ref?.scrollTo(0, itemHeight * +value - itemHeight * 2);
  };

  useEffect(() => {
    if (hourRef.current) {
      customScroll(hourRef?.current, selectedHours);
    }
    if (minuteRef.current) {
      customScroll(minuteRef.current, selectedMintues);
    }
    handleTimeChange(selectedHours + ":" + selectedMintues);
    // eslint-disable-next-line
  }, [openTimer, selectedHours, selectedMintues]);

  return (
    <S.TimerPickerWrapper>
      <S.TimePickerStyle
        onClick={() => {
          setOpenTimer((prev) => !prev);
        }}
        value={value}
        ref={timerInputRef}
        type="time"
        disabled={disable}
        readOnly
      ></S.TimePickerStyle>
      {openTimer && (
        <S.TimePopupContainer ref={timePickerPopup}>
          <S.ScrollTimeWrapper ref={hourRef}>
            {hours?.map((h) => (
              <S.StylePForTime
                disable={minHours > +h}
                onClick={() => setSelectedHours(h)}
                ref={hourValueRef}
                selected={selectedHours === h}
                key={h}
              >
                {h}
              </S.StylePForTime>
            ))}
          </S.ScrollTimeWrapper>
          <S.ScrollTimeWrapper ref={minuteRef}>
            {minutes?.map((m) => (
              <S.StylePForTime
                onClick={() => {
                  setSelectedMintues(m);
                  setOpenTimer(false);
                }}
                disable={minMintues > +m}
                selected={selectedMintues === m}
                key={m}
              >
                {m}
              </S.StylePForTime>
            ))}
          </S.ScrollTimeWrapper>
        </S.TimePopupContainer>
      )}
    </S.TimerPickerWrapper>
  );
};

export default TimePicker;
