import { CloseRounded, SettingsRounded } from "@mui/icons-material";
import * as S from "./ControlModalStyles";
import FormControls from "../farm-controls/FormControls";
import { CColorCodeVariables } from "../../constants/color.constants";

const ControlModal: React.FC<{
  open: boolean;
  controlType: "Aerators" | "Feeders";
  handleControlModalClose: () => void;
  devices: [...any];
}> = ({ open, handleControlModalClose, controlType, devices }) => {
  return (
    <S.StyledControlModal open={open} onClose={handleControlModalClose}>
      <S.ModalSection>
        <div style={{ backgroundColor: `rgba(${CColorCodeVariables.white}, 0.9)`, height: '100%' }} >
        <S.ControlModalHeader>
          <div>
            <S.ControlModalHeading>
              {controlType} Control <SettingsRounded />
            </S.ControlModalHeading>
            <S.ControlModalSubHeader>
              {controlType} information and configurations.
            </S.ControlModalSubHeader>
          </div>
          <CloseRounded
            sx={{ cursor: "pointer" }}
            onClick={handleControlModalClose}
          />
        </S.ControlModalHeader>
        <S.ControlModalContent>
          <FormControls controlType={controlType} devices={devices}/>
        </S.ControlModalContent>
        </div>
      </S.ModalSection>
    </S.StyledControlModal>
  );
};

export default ControlModal;
