import { Avatar, Typography } from "@mui/material";
import * as S from "./HeaderStyles";
import { useAppSelector } from "../../store/hooks";
import { userDetails } from "../../store/slices/userSlice";
import NavLogo from "../../assets/icons/Agrimor Outline black-Dark gray.svg";
import { useNavigate } from "react-router-dom";
import { CColorCodeVariables } from "../../constants/color.constants";
import SMS from "../../assets/icons/sms.svg";
import Notifications from "../../assets/icons/notification.svg";

const Header = () => {
  const userInformation: any = useAppSelector(userDetails);
  const navigate = useNavigate();

  return (
    <S.HeaderWrapper>
      <div>
        <img
          onClick={() => navigate("/")}
          width="38"
          height="40"
          className="navLogo"
          src={NavLogo}
          alt="Aquaclture"
          onKeyDown={()=>{}}
        />
      </div>
      <S.HeaderUserProfileWarp>
        <img className="cursor-pointer" src={SMS} alt="sms" />
        <img className="cursor-pointer" src={Notifications} alt="notifications" />
        <div style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}>
          <Avatar alt={userInformation?.name} src={userInformation?.profile} />
          <Typography
            sx={{ color: `rgba(${CColorCodeVariables.moonlitMist}, 1)` }}
          >
            {userInformation?.name}
          </Typography>
        </div>
      </S.HeaderUserProfileWarp>
    </S.HeaderWrapper>
  );
};

export default Header;
