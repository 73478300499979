import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { StyledNotificationAlert, StyledNotificationMessageContent, StyledToastMessageContent } from "./StyledNotificationAlert";
import dangerIcon from '../../assets/icons/alert-danger.svg';
import unionCloseIcon from '../../assets/icons/union-close.svg';
import listArrowDownIcon from '../../assets/icons/list-arrow-down.svg';
import { changeNotificationCollapsedState, delayRemoveToastMessages, removeAllNotificationMessage, removeSingleNotificationMessage } from "../../store/slices/notificationSlice";
import { ENotificationAlertTypes } from "../../constants/service.contants";

interface NotificationAlertProps {
}

const NotificationAlert: FC<NotificationAlertProps> = ({ ...rest }) => {
    const dispatch = useAppDispatch();
    const { notificationMessageList, toastMessageList, isNotificationCollapsed, toastDelayIndex, toastTimeout } = useAppSelector(state => state.notification);

    useEffect(() => {
        if (toastDelayIndex !== null) {
            setTimeout(() => {
                dispatch(delayRemoveToastMessages({ indexToRemove: toastDelayIndex }));
            }, toastTimeout);
        }
    }, [toastDelayIndex, dispatch, toastTimeout]);

    return (
        <StyledNotificationAlert>
            <div className="toast-message-wrap">
                {
                    toastMessageList?.length ? toastMessageList?.map((ele: any, index: number) => (
                        <StyledToastMessageContent key={index + 1}
                            alerttype={ele?.alertType as ENotificationAlertTypes}>
                            <div className="toast-alert-container">
                                <div className="left-panel">
                                    <div className="danger-icon-wrap">
                                        <img src={dangerIcon} className="danger-icon" alt="alert" />
                                    </div>
                                </div>

                                <div className="center-panel">
                                    <div className="header">
                                        <span className="title">
                                            <label>{ele?.title || ''}</label>
                                        </span>
                                        <span className="date-time">
                                            <label>{ele?.dateTimeInFormat || ''}</label>
                                        </span>
                                    </div>
                                    <div className="content">
                                        <p className="para">
                                            {ele?.message || ''}
                                            {/* Dissolved Oxygen value falls below optimal range at <span className="milli-gram">2.8 mg/L</span> for Intake 1. */}
                                        </p>
                                    </div>
                                </div>

                                {/* <div className="right-panel">
                                    <div className="date-time">
                                        <label>{ele?.dateTimeInFormat || ''}</label>
                                    </div>
                                    <div className="total-notification">
                                        <label>{index + 1} of {toastMessageList.length}</label>
                                    </div>
                                </div> */}

                            </div>

                            {/* <div className="close-btn-container" onClick={() => {
                                dispatch(removeSingleToastMessage({ indexToRemove: ele?.indexValue }));
                            }}>
                                <img src={unionCloseIcon} className="close-icon" alt="close" />
                                <span className="close-text">Close</span>
                            </div> */}
                        </StyledToastMessageContent>
                    )) : <></>
                }
            </div>

            <div className="notification-message-wrap">
                {
                    notificationMessageList?.length ? notificationMessageList?.map((ele: any, index: number) => (
                        <StyledNotificationMessageContent key={index + 1}
                            alerttype={ele?.alertType as ENotificationAlertTypes} iscollapsed={isNotificationCollapsed ? 'true' : 'false'}>
                            <div className="notification-alert-container">
                                <div className="left-panel">
                                    <div className="danger-icon-wrap">
                                        <img src={dangerIcon} className="danger-icon" alt="alert" />
                                    </div>
                                </div>

                                <div className="center-panel">
                                    <div className="header">
                                        <span className="title">
                                            <label>{ele?.title || ''}</label>
                                        </span>
                                    </div>
                                    <div className="content">
                                        <p className="para">
                                            Dissolved Oxygen value falls below optimal range at <span className="milli-gram">2.8 mg/L</span> for Intake 1.</p>
                                    </div>
                                </div>

                                <div className="right-panel">
                                    <div className="date-time">
                                        <label>{ele?.dateTimeInFormat || ''}</label>
                                    </div>
                                    <div className="total-notification">
                                        <label>{index + 1} of {notificationMessageList.length}</label>
                                    </div>
                                </div>

                            </div>

                            <div className="close-btn-container" onClick={() => {
                                if (notificationMessageList.length > 1 && index === 0) {
                                    dispatch(removeAllNotificationMessage())

                                } else {
                                    dispatch(removeSingleNotificationMessage({ indexToRemove: index }))
                                }
                            }} onKeyDown={()=>{}}>
                                <img src={unionCloseIcon} className="close-icon" alt="close" />
                                <span className="close-text">Close {(notificationMessageList.length > 1 && index === 0) && 'All'}</span>
                            </div>

                            {(notificationMessageList.length > 1 && index === 0) ? <div className="collapse-expand-container"
                                onClick={() => { dispatch(changeNotificationCollapsedState()) }} onKeyDown={()=>{}}>
                                <img src={listArrowDownIcon} className={`arrow-down-icon ${isNotificationCollapsed ? 'collapsed' : ''}`}
                                    alt="arrow-down" />
                            </div> : <></>}
                        </StyledNotificationMessageContent>
                    )) : <></>
                }
            </div>
        </StyledNotificationAlert>
    )
}
export default NotificationAlert;