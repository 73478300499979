import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import SideNavigationBar from "../side-navigation-bar/SideNavigationBar";
import "./LayoutContainer.scss";

const LayoutContainer = () => {

  return (
    <>
      <SideNavigationBar />
      <Header />
      <div className="appContantWrapper">
        <Outlet />
      </div>
    </>
  );
};

export default LayoutContainer;
