import { Modal, styled } from "@mui/material";
import { CColorCodeVariables } from "../../../constants/color.constants";

export const ConformationModal = styled(Modal)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

export const ModalWrapper = styled('section')(({ theme }) => ({
    background: `rgba(${CColorCodeVariables.white}, 0.8)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    borderRadius: '8px',
    width: '580px',
    color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
    padding: '16px',
    textAlign: 'center',
    // height: '332px',
    '&:focus-visible': {
        outline: 'unset'
    },

    [theme.breakpoints.down('sm')]: {
        margin: '0 1rem'
    }
}))