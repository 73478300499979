const emailRegex = /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,10}$/;
const upperCaseRegex = /[A-Z]/;
const numberRegex = /\d/;
const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;

export const checkEmail = (email: string) => emailRegex.test(email);

export const upperCaseExist = (text: string) => upperCaseRegex.test(text)

export const numberExist = (text: string) => numberRegex.test(text);

export const symbolExist = (text: string) => symbolRegex.test(text);