import { Grid } from "@mui/material";
import * as S from "./CensorReadingScaleCard";
import { IAvgCensorReading } from "../../../interfaces/app-interfaces";
import CensorStatusBradge from "../../../common/censor-status-bradge/CensorStatusBradge";
import GradientSlider from "../../../common/gradient-slider/GradientSlider";
import { CENSOR_DETAILS_TYPE_COLORS } from "../../../constants/sensor.constants";
import { CColorCodeVariables } from "../../../constants/color.constants";

const CensorReadingScaleCard: React.FC<{
  censorReadings: IAvgCensorReading; selected: boolean, handleSelectedReading: (key: number) => void; timestamp: string; range: any
}> = ({ censorReadings, selected, handleSelectedReading, timestamp = '', range = {}}) => {
  if (censorReadings) {
    return (
      <Grid item lg={6} xl={6} md={6} sm={6} xs={6}>
        <S.IntakeCensorReadingWrapperStyle onClick={() => handleSelectedReading(censorReadings?.key)} sx={{ borderColor: selected ? `rgba(${CColorCodeVariables.turquoiseOasis}, 1)` : '' }} >
          <S.IntakeCensorNameStyle
            className="c-fw-6"
            style={{ color: "#3C404399" }}
          >
            {censorReadings?.type} <br />
          </S.IntakeCensorNameStyle>
          <CensorStatusBradge type={censorReadings.state} />
          <h1
            className="c-fw-6"
            style={{ marginBottom: "0", fontSize: "1rem" }}
          >
            {censorReadings?.value}{" "}
            <span style={{ fontSize: "0.9rem" }}>{censorReadings?.unit}</span>
          </h1>
          <GradientSlider
            value={censorReadings?.readingVal}
            colors={CENSOR_DETAILS_TYPE_COLORS[(censorReadings?.type)?.toUpperCase()]?.colors}
            range={range}
          />
          <p
            className="c-fw-4"
            style={{
              fontSize: "0.75rem",
              paddingTop: "0.2rem",
              color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
            }}
          >
            {/*<i>{"Optimal at <0.1mg/L"}</i>*/}
            <i>Last updated: {new Date(timestamp).toLocaleString()}</i>
          </p>
        </S.IntakeCensorReadingWrapperStyle>
      </Grid>
    );
  }
  return <></>;
};

export default CensorReadingScaleCard;
