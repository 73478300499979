import { styled } from "@mui/material"
import { CColorCodeVariables } from "../../../constants/color.constants"

export const CensorReadingChartWrapper = styled('section')(({ theme }) => ({
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.25)`,
    background: `rgba(${CColorCodeVariables.white}, 1)`,
    borderRadius: "4px",
    margin: "0.5rem",
    [theme.breakpoints.down('md')]: {
        margin: '0'
    }
}))