import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  hideToast,
  toastDetails,
} from "../store/slices/toastSlice";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar } from "@mui/material";
import React from "react";
import { IToastState } from "../interfaces/service.interface";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = () => {
  const dispatch = useAppDispatch();
  const toastList = useAppSelector(toastDetails);

  const handleClose = (
    
    toast: IToastState,
    reason?: string
  ) => {
    if (reason !== "clickaway") {
      dispatch(hideToast(toast?.key));
    }
  };

  const toastType = (toast: IToastState) => {
    return (
      <Alert
        onClose={( event?: React.SyntheticEvent | Event, reason?: string) => handleClose(toast, reason)}
        severity={toast?.type}
        sx={{ width: "100%" }}
      >
        {toast?.message}
      </Alert>
    );
  };

  return (
    <>
      {toastList?.map((toast: IToastState, index: number) => (
        <Snackbar
          sx={{ top: `${(index + 1) * 3 * 2.5}% !important` }}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={toast.key}
          onClose={(event?: React.SyntheticEvent | Event, reason?: string) => handleClose(toast, reason)}
          open={toast?.open}
        >
          {toastType(toast)}
        </Snackbar>
      ))}
    </>
  );
};

export default Toast;
