import { CloseRounded, WarningRounded } from "@mui/icons-material";
import * as S from "./ControlSwitchConfirmationModalStyle";
import React from "react";
import { Button } from "@mui/material";
import { CColorCodeVariables } from "../../../constants/color.constants";

const ControlSwitchConfirmationModal: React.FC<{
  type: string;
  control: string;
  open: boolean
  handleModalClose: () => void,
  handleConformation: () => void
}> = ({ type, control, handleModalClose, open, handleConformation }) => {
  return (
    <S.ConformationModal open={open} onClose={() => handleModalClose()} >
      <S.ModalWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CloseRounded onClick={() => handleModalClose()} fontSize="medium" sx={{ cursor: "pointer" }} />
        </div>
        <WarningRounded sx={{ fontSize: "8rem" }} />
        <h1 className="c-fw-5 c-m-0" style={{ marginBottom: "0.5rem" }}>
          Are you sure?
        </h1>
        <p style={{ marginBottom: "2rem" }}>
          You're about to turning {type} the {control} smart switch.
        </p>
        <Button
          sx={{
            padding: "8px 40px !important",
            borderRadius: "4px",
            color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
          }}
          onClick={() => handleModalClose()}
        >
          Cancel
        </Button>
        <Button
          sx={{
            padding: "8px 40px !important",
            background:
              type === "on"
                ? "linear-gradient(84.85deg, #00B09B -21.2%, #3DB0C9 87.5%)"
                : "linear-gradient(84.85deg, #B02000 -21.2%, #C94E3D 87.5%)",
            borderWidth:
              `0.2px, 0.2px, 0.2px, 0px solid rgba(${CColorCodeVariables.slateBlue}, 1)`,
            color: "white",
          }}
          onClick={() => handleConformation()}
        >
          Proceed
        </Button>
      </S.ModalWrapper>
    </S.ConformationModal>
  );
};

export default ControlSwitchConfirmationModal;
