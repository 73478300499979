import {  createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {}

const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        saveUserDetails: (state, action) => {
            return action.payload
        },
        clearUserDetails: (state) => {
            return {}
        }
    }
})

export const userDetails = (state: RootState) => state.userDetails

export default userDetailsSlice.reducer

export const { saveUserDetails, clearUserDetails } = userDetailsSlice.actions
