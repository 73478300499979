import { Box, styled } from '@mui/material';
import { CColorCodeVariables } from '../../../constants/color.constants';

export const StyledIntelligentControlSwitchSkeleton = styled(Box)<{ isactive: string }>(({ isactive }) => ({
    minWidth: '320px',
    padding: '12px 20px 12px 20px',
    borderRadius: '8px',
    background: `rgba(${CColorCodeVariables.paleSilver}, 1)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    marginRight: '10px',
    marginBottom: '10px',
    '&:last-of-type': {
        marginRight: '0px'
    },
    '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        '& .title-wrap': {
            width: 'calc(100% - 65px)',
            '& .title': {
                width: '100%',
                marginRight: '10px',
                fontWeight: 600,
                fontSize: '28px',
                background: CColorCodeVariables.skeletonBg
            }
        },
        '& .img-indicator': {
            width: '55px',
            '& .indi-icon': {
                width: '100%',
                height: '35px',
                background: CColorCodeVariables.skeletonBg
            }
        }
    },
    '& .content': {
        '& .para-row': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '30px',
            '& .label-col': {
                width: '100%',
                fontWeight: 400,
                fontSize: '14px',
                marginRight: '10px',
                height: '35px',
                background: CColorCodeVariables.skeletonBg
            },
            '& .status-col': {
                width: '70px',
                '& .switch': {
                    width: '100%',
                    height: '35px',
                    background: CColorCodeVariables.skeletonBg
                }
            }
        }
    },
    '& .footer': {
        '& .history': {
            width: '60%',
            '& .date-time': {
                height: '30px',
                background: CColorCodeVariables.skeletonBg
            }
        }
    }
}));