import * as S from "./ControlStatusStyles";
import CensorIcon from "../../assets/icons/icon.svg";
import React, {FC, useState} from "react";
import {
  controlStatusSwitchLabelTypes,
  controlStatusTypes,
} from "../../constants/sensor.constants";
import ControlStatusSwitchLabel from "../../common/control-status-switch/ControlStatusSwitch";
import WifiOnlineIcon from "../../common/common-svg-icons/WifiOnline";
import WifiOffline from "../../common/common-svg-icons/WifiOffline";
import ControlModal from "../control-modal/ControlModal";
import {ControlProps} from "../../pages/dashboard/DashboardPage";

const ControlStatus: FC<{controlData: ControlProps}> = ({ controlData }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenControlModal = () =>  { 
    setOpenModal(true)
  }
  const handleControlModalClose = () => {
    setOpenModal(false);
  }


  return (
    <>
    <S.ControlStatusWarpperStyle onClick={handleOpenControlModal} >
      <S.ControlStatusNameStyle>
        {controlData?.name}
        <img src={CensorIcon} alt="expand-details" />
      </S.ControlStatusNameStyle>
      <section>
        {controlStatusTypes.map((status) => (
          <S.ControlStatusSwitchLabelWrapper key={status?.id}>
            {status?.label}
            <span style={{ display: 'flex', gap: '0.5rem' }} >
            <ControlStatusSwitchLabel
              labelStatus={true}
              count={controlData[status.code as keyof ControlProps]?.active}
              labelText={status?.switchLabelType ===  controlStatusSwitchLabelTypes.TEXT ? 'ON' : <WifiOnlineIcon />}
            />
            <ControlStatusSwitchLabel
              labelStatus={false}
              count={controlData[status.code as keyof ControlProps]?.offline}
              labelText={status?.switchLabelType ===  controlStatusSwitchLabelTypes.TEXT ? 'OFF' : <WifiOffline />}
            />
            </span>
          </S.ControlStatusSwitchLabelWrapper>
        ))}
      </section>
    </S.ControlStatusWarpperStyle>
    <ControlModal open={openModal} controlType={controlData?.name} handleControlModalClose={handleControlModalClose} devices={controlData?.devices} />
    </>
  );
};

export default ControlStatus;
