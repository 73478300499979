import React from "react"

const WifiOnlineIcon: React.FC<{ width?: number, height?: number, color?: string }> = ({ width = 14, height = 15, color = 'white' }) => {
    return <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.86426 7.4066C5.37259 5.46994 8.63343 5.46994 11.1418 7.4066" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.16699 5.3766C4.70199 2.6466 9.29866 2.6466 12.8337 5.3766" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.96094 9.53576C5.79844 8.11243 8.19594 8.11243 10.0334 9.53576" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.4834 11.671C6.40507 10.9593 7.6009 10.9593 8.52257 11.671" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
}

export default WifiOnlineIcon;
