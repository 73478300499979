import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import agrimorLogoLightImage from "../../assets/icons/agrimor-logo-light.svg";
import agrimorLogoDarkImage from "../../assets/icons/agrimor-logo-dark.svg";
import {
  CheckCircleRounded,
  ErrorRounded,
  Visibility,
  VisibilityOff,
  CancelRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { WINDOW_INNER_WIDTH } from "../../constants/themes.constants";
import { Formik } from "formik";
import * as Yup from "yup";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import type { FC } from "react";
import { SignupPageContainerStyled } from "./SignupPageStyles";
import { userRegister } from "../../services/api-service/authApi";
import SignupSuccessPage from "./SignupSuccessPage";
import SignupUnsuccessPage from "./SignupUnsuccessPage";
import { LoadingButton } from "@mui/lab";
import countryCodes from "country-codes-list";
import { CColorCodeVariables } from "../../constants/color.constants";

export interface IUserRegisteration {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
}

export interface IRegisterForm {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  countryCode: any;
  phoneNo: string;
  submit: null;
}

interface SignupPageProps { }

const SignupPage: FC<SignupPageProps> = ({ ...rest }) => {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();

  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerFail, setRegisterFail] = useState(false);
  const [userDetails, setUserdetails] = useState<IRegisterForm>({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryCode: null,
    phoneNo: "",
    submit: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const countryNameObject: any = countryCodes.customList(undefined, '{countryNameEn}');
  const countryCallingCodeObject: any = countryCodes.customList(undefined, '+{countryCallingCode}');
  let countryList: any[] = Object.keys(countryNameObject).map((key: string, index) => {
    return { id: String(index + 1), code: countryCallingCodeObject[key] || '', name: countryNameObject[key] || '' }
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const userRegisteration = async (values: IRegisterForm) => {
    const countryCode: string = countryList.filter(obj => obj?.id === values?.countryCode?.id)?.[0]?.code || '';
    const user: IUserRegisteration = {
      name: values.fullName,
      email: values.email,
      phoneNumber: `${countryCode} ${values?.phoneNo}`,
      password: values?.password,
    };

    setIsLoading(true);
    const apiResponse = await userRegister(user);
    setIsLoading(false);
    if (apiResponse.status) {
      setRegisterSuccess(true);
      setRegisterFail(false);
      setErrorMessage("");
    } else {
      setRegisterFail(true);
      setErrorMessage(
        apiResponse?.response?.data?.message?.length
          ? apiResponse?.response?.data?.message?.join(", ")
          : ""
      );
    }
  };

  if (registerSuccess) {
    return <SignupSuccessPage />;
  }

  if (registerFail) {
    return (
      <SignupUnsuccessPage
        user={userDetails}
        userRegisteration={userRegisteration}
        setRegisterFail={setRegisterFail}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    );
  }

  return (
    <Formik
      initialValues={userDetails}
      validationSchema={Yup.object().shape({
        fullName: Yup.string()
          .max(255, "Full name must be at most 255 characters")
          .required("Full Name is required")
          .matches(
            /^[a-zA-Z\s]*$/,
            "Full name should not contain special characters"
          ),
        // countryCode: Yup.string().max(255).required("Country code is required"),
        countryCode: Yup.object().required("Country code is required"),
        phoneNo: Yup.string()
          .max(10, "Phone Number must be at most 10 characters")
          .required("Phone Number is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        // password: Yup.string().required('Password is required').min(8, 'Password must be atleast 3 char long').max(255),
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .max(255, "Password must be at most 255 characters")
          .test(
            "hasUppercase",
            "Password must contain at least one uppercase letter",
            (value) => /[A-Z]/.test(value)
          )
          .test(
            "hasNumber",
            "Password must contain at least one number",
            (value) => /\d/.test(value)
          )
          .test(
            "hasSymbol",
            "Password must contain at least one symbol",
            (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
          ),
        confirmPassword: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("password")], "Confirm Password does not match"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setUserdetails(values);
          await userRegisteration(values);
        } catch (err: any) {
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
        setFieldValue
      }) => {
        // const isEmailValid = '';
        const isLengthValid = values.password.length >= 8;
        const hasUppercase = /[A-Z]/.test(values.password);
        const hasNumber = /\d/.test(values.password);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(values.password);
        return (
          <form noValidate onSubmit={handleSubmit} {...rest}>
            <SignupPageContainerStyled container ismobile={isMobile.toString()}>
              {!isMobile ? (
                <Grid item className="left-grid-item">
                  <img
                    src={agrimorLogoLightImage}
                    className="agrimor-logo-light"
                    alt="Agrimor"
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid item className="right-grid-item">
                <Box className="box-wrap">
                  <Grid container className="grid-container">
                    {isMobile ? (
                      <Grid item mb={4} xl={12} lg={12} md={12} sm={12} xs={12} className="agrimor-logo-wrap">
                        <img
                          src={agrimorLogoDarkImage}
                          className="agrimor-logo-dark"
                          alt="Agrimor"
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="h3" className="title">
                        Sign Up
                      </Typography>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                      <Typography className="label"> Full Name </Typography>

                      <TextField
                        type="text"
                        fullWidth
                        className="input-field"
                        placeholder="Full Name"
                        name="fullName"
                        variant="outlined"
                        error={Boolean(touched.fullName && errors.fullName)}
                        autoComplete="off"
                        value={values.fullName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={
                          touched.fullName
                            ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SvgIcon fontSize="small"
                                    sx={{ color: Boolean(errors.fullName) ? "red" : "green" }} >
                                    {Boolean(errors.fullName) ? (
                                      <ErrorRounded />
                                    ) : (
                                      <CheckCircleRounded />
                                    )}
                                  </SvgIcon>
                                </InputAdornment>
                              ),
                            }
                            : {}
                        }
                      />
                      <FormHelperText error>
                        {touched.fullName && errors.fullName}
                      </FormHelperText>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                      <Typography className="label"> Phone No </Typography>

                      <Box style={{ display: "flex" }}>
                        <span style={{ width: "120px", marginRight: "10px" }}>
                          <Autocomplete id="countryCode" options={countryList}
                            getOptionLabel={(option) => `${option?.code} ${option?.name}`} disableClearable
                            onChange={(event, value) => { setFieldValue("countryCode", value) }}
                            renderInput={(params) => (
                              <TextField {...params} className="select-field" variant="outlined" name="countryCode"
                                onBlur={handleBlur} onChange={handleChange} value={values.countryCode?.id || ''}
                                error={Boolean(touched.countryCode && errors.countryCode)} />
                            )}
                            renderOption={(props, option) => (
                              <MenuItem {...props} value={option?.id}
                                sx={{
                                  minWidth: "200px !important",
                                  minHeight: "48px",
                                  display: "flex !important",
                                  justifyContent: "space-between !important",
                                  alignItems: "center !important",
                                  ":hover": {
                                    backgroundColor: `rgba(${CColorCodeVariables.turquoiseOasis}, 1) !important`,
                                  },
                                }}>
                                <Typography sx={{ display: 'flex !important', alignItems: 'center !important' }}>
                                  <Typography sx={{ padding: "0px 10px 0px 0px", width: '40px', fontWeight: 600 }}>
                                    {option?.code}
                                  </Typography>
                                  <Typography sx={{ width: '150px', whiteSpace: "break-spaces", padding: '2px 0px' }}>{option?.name}</Typography>
                                </Typography>

                                <div>
                                  {values?.countryCode?.id === option?.id && (
                                    <CheckCircleRounded style={{ color: "green", position: "relative", top: '4px' }}
                                    />
                                  )}
                                </div>
                              </MenuItem>
                            )}
                            PopperComponent={(props) => (
                              <Popper {...props} placement="bottom-start"
                                sx={{
                                  width: '280px !important',
                                  marginTop: '5px !important',
                                  borderRadius: '4px'
                                }} />
                            )}
                          />

                          {/* <TextField
                            fullWidth
                            select
                            className="select-field"
                            variant="outlined"
                            name="countryCode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.countryCode}
                            error={Boolean(
                              touched.countryCode && errors.countryCode
                            )}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  sx: {
                                    height: "300px",
                                    overflowY: "auto",
                                  },
                                },
                              },
                              renderValue: (selected) => {
                                const selectedOption = countryList.find(
                                  (option) => option.code === selected
                                );
                                return <div> {selectedOption?.code}</div>;
                              },
                            }}
                          >
                            {countryList?.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option?.code}
                                sx={{
                                  minWidth: "200px !important",
                                  height: "48px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "0px 10px",
                                  ":hover": {
                                    backgroundColor: `rgba(${CColorCodeVariables.turquoiseOasis}, 1)`,
                                  },
                                }}
                              >
                                <div>
                                  <span style={{ padding: "0px 10px" }}>
                                    {option?.code}
                                  </span>
                                  <span>{option?.name}</span>
                                </div>
                                <div>
                                  {values.countryCode === option?.code && (
                                    <CheckCircleRounded
                                      style={{
                                        color: "green",
                                        position: "relative",
                                        top: "4px",
                                      }}
                                    />
                                  )}
                                </div>
                              </MenuItem>
                            ))}
                          </TextField> */}
                        </span>

                        <span style={{ width: "calc(100% - 80px)" }}>
                          <TextField
                            type="number"
                            fullWidth
                            className="input-field"
                            placeholder="Phone No"
                            name="phoneNo"
                            variant="outlined"
                            error={Boolean(touched.phoneNo && errors.phoneNo)}
                            autoComplete="off"
                            value={values.phoneNo}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputProps={
                              touched.phoneNo
                                ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <SvgIcon fontSize="small"
                                        sx={{ color: Boolean(errors.phoneNo) ? "red" : "green" }} >
                                        {Boolean(errors.phoneNo) ? (<ErrorRounded />) : (<CheckCircleRounded />)}
                                      </SvgIcon>
                                    </InputAdornment>
                                  ),
                                }
                                : {}
                            }
                          />
                        </span>
                      </Box>

                      <FormHelperText error>
                        {touched.phoneNo && errors.phoneNo}
                      </FormHelperText>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                      <Typography className="label"> Email Address </Typography>

                      <TextField
                        type="email"
                        fullWidth
                        className="input-field"
                        placeholder="Email"
                        name="email"
                        variant="outlined"
                        error={Boolean(touched.email && errors.email)}
                        autoComplete="off"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={
                          touched.email
                            ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SvgIcon
                                    fontSize="small"
                                    sx={{
                                      color: Boolean(errors.email)
                                        ? "red"
                                        : "green",
                                    }}
                                  >
                                    {Boolean(errors.email) ? (
                                      <ErrorRounded />
                                    ) : (
                                      <CheckCircleRounded />
                                    )}
                                  </SvgIcon>
                                </InputAdornment>
                              ),
                            }
                            : {}
                        }
                      />
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                      <Typography className="label"> Password </Typography>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        className="input-field"
                        placeholder="Password"
                        name="password"
                        variant="outlined"
                        error={Boolean(touched.password && errors.password)}
                        autoComplete="new-password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                sx={{ height: "24px", width: "24px" }}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      {touched.password && errors.password ? (
                        <>
                          <Typography
                            style={{ color: "gray", margin: "8px 0px" }}
                          >
                            Your password must at least
                          </Typography>
                          <Box className="pass-err-wrap">
                            <Box
                              style={{
                                color: isLengthValid
                                  ? `rgba(${CColorCodeVariables.turquoiseOasis}, 0.8)`
                                  : `rgba(${CColorCodeVariables.vibrantRed}, 1)`,
                                margin: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <SvgIcon
                                fontSize="small"
                                sx={{
                                  color: isLengthValid
                                    ? `rgba(${CColorCodeVariables.turquoiseOasis}, 0.8)`
                                    : `rgba(${CColorCodeVariables.vibrantRed}, 1)`,
                                  height: "15px",
                                  width: "15px",
                                  marginRight: "5px",
                                }}
                              >
                                {isLengthValid ? (
                                  <CheckCircleRounded />
                                ) : (
                                  <CancelRounded />
                                )}
                              </SvgIcon>
                              <Typography className="display-msg">
                                Made up of 8 characters
                              </Typography>
                            </Box>

                            <Box
                              style={{
                                color:
                                  hasUppercase && hasNumber && hasSymbol
                                    ? `rgba(${CColorCodeVariables.turquoiseOasis}, 0.8)`
                                    : `rgba(${CColorCodeVariables.vibrantRed}, 1)`,
                                margin: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <SvgIcon
                                fontSize="small"
                                sx={{
                                  color:
                                    hasUppercase && hasNumber && hasSymbol
                                      ? `rgba(${CColorCodeVariables.turquoiseOasis}, 0.8)`
                                      : `rgba(${CColorCodeVariables.vibrantRed}, 1)`,
                                  height: "15px",
                                  width: "15px",
                                  marginRight: "5px",
                                }}
                              >
                                {hasUppercase && hasNumber && hasSymbol ? (
                                  <CheckCircleRounded />
                                ) : (
                                  <CancelRounded />
                                )}
                              </SvgIcon>
                              <Typography className="display-msg">
                                Contains uppercase, number and symbol
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                      <Typography className="label">
                        Confirm Password
                      </Typography>
                      <TextField
                        type={showConfirmPassword ? "text" : "password"}
                        fullWidth
                        className="input-field"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        variant="outlined"
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        autoComplete="off"
                        value={values.confirmPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle Confirm Password visibility"
                                onClick={() => {
                                  setShowConfirmPassword(!showConfirmPassword);
                                }}
                                sx={{ height: "24px", width: "24px" }}
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FormHelperText error>
                        {touched.confirmPassword && errors.confirmPassword}
                      </FormHelperText>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={3}>
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      {errors.submit}

                      <Typography>
                        <LoadingButton
                          loading={isLoading}
                          type="submit"
                          variant="contained"
                          className="submit-btn"
                          disabled={!isValid || !dirty}
                        >
                          SIGN UP
                        </LoadingButton>
                      </Typography>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={2}>
                      <Typography className="register-wrap">
                        Already have an account?
                        <Button
                          sx={{
                            fontWeight: 600,
                            color: `rgba(${CColorCodeVariables.turquoiseOasis}, 1)`,
                          }}
                          onClick={() => navigate("/login")}
                        >
                          Login
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </SignupPageContainerStyled>
          </form>
        );
      }}
    </Formik>
  );
};

export default SignupPage;
