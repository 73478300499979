import { styled } from "@mui/material";

export const FarmAddress = styled('p')(({ theme }) => ({
    color: theme?.palette?.color?.subTextGrey,
    marginBottom: '1rem'
}))

export const FarmName = styled('h1')({
    color: '#242731', 
    marginBottom: '0'
})