import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { CColorCodeVariables } from "../../constants/color.constants";

export const StyledConfigSwitch = styled(Box)<{ activeswitch: string, disabledswitch: string, size: 'lg' | undefined }>(({ theme, activeswitch, disabledswitch, size }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height:  size === 'lg' ? '30px' : '21px',
    width:  size === 'lg' ? '120px' : '56px',
    cursor: (disabledswitch === 'true') ? 'not-allowed' : 'pointer',
    '& .switch-input': {
        visibility: 'hidden',
    },
    '& .switch-input:checked + .switch-label': {
        background: (activeswitch === 'true') ? `linear-gradient(0deg, rgba(${CColorCodeVariables.black}.4), rgba(${CColorCodeVariables.black}, 0.4)), linear-gradient(84.85deg, rgba(${CColorCodeVariables.turquoiseOasis}, 0.8) -21.2%, rgba(${CColorCodeVariables.aquaBlue}, 0.8) 87.5%)` : `linear-gradient(84.85deg, rgba(${CColorCodeVariables.turquoiseOasis}, 0.8) -21.2%, rgba(${CColorCodeVariables.aquaBlue}, 0.8) 87.5%)`,
    },
    '& .switch-input:checked + .switch-label::before': {
        transform: size === 'lg' ? 'translateX(58px)' :  'translateX(28px)',
    },
    '& .switch-label': {
        position: 'absolute',
        cursor: (disabledswitch === 'true') ? 'not-allowed' : 'pointer',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: '#ccc',
        borderRadius: '4px',
        transition: 'background-color 0.4s',
        background: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
        '&::before': {
            position: 'absolute',
            content: '""',
            height: size === 'lg' ? '26px' : '17px',
            width: size === 'lg' ? '55px' : '24px',
            left: '2px',
            bottom: '2px',
            backgroundColor: 'white',
            borderRadius: '4px',
            transition: 'transform 0.4s',
            boxShadow: `0px 4px 4px 0px rgba(${CColorCodeVariables.black}, 0.25)`
        }
    },
    '& .on, .off': {
        color: `rgba(${CColorCodeVariables.white}, 1)`,
        zIndex: '1',
        fontWeight: 600,
        fontSize: size === 'lg' ? '16px' : '14px',
        lineHeight: '16.8px'
    },

    '& .on': {
        padding: size === 'lg' ? '0px 5px 0px 20px' : '0px 3px 0px 5px',
    },

    '& .off': {
        padding: size === 'lg' ? '0px 20px 0px 5px' : '0px 3px 0px 5px',
    }

}));