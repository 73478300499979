export const CColorCodeVariables = {
    skeletonBg: 'rgba(100, 116, 139, 0.17)',
    paleSilver: '244, 244, 244',
    slateBlue: '100, 116, 139',
    white: '255, 255, 255',
    black: '0, 0, 0',
    red: '255, 0, 0',
    charcoalGray: '82, 82, 82',
    turquoiseOasis: '0, 176, 155',
    sunburstOrange: '255, 178, 1',
    scarletBlaze: '255, 1, 13',
    aquaBlue: '61, 176, 201',
    shadowSlate: '60, 64, 67',
    serenityBlue: '124, 141, 181',
    midnightSlate: '56, 65, 76',
    stormyBlue: '85, 107, 126',
    crimsonRed: '176, 0, 74',
    shadowGray: '22, 22, 22',
    slateGray: '30, 30, 30',
    graphiteGray: '51, 51, 51',
    moonlitMist: '252, 253, 254',
    darkGray: '99, 99, 99',
    gunmetalGray: '97, 97, 97',
    darkGray00: '81, 81, 81',
    vibrantRed: '208, 31, 31',
    paleGray: '245, 245, 245',

}