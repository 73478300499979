import { styled } from "@mui/material";
import { CColorCodeVariables } from "../../../../constants/color.constants";

export const StyledFeederScheduleForm = styled('div')<{}>(({ theme }) => ({
    height: '100%',
    width: 'auto',
    boxSizing: 'border-box',
    backgroundColor: `rgba(${CColorCodeVariables.white}, 1)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    borderRadius: '8px',
    marginLeft: '0.8rem',
    padding: '20px',
    [theme?.breakpoints?.down('md')]: {
        marginLeft: '0',
        // marginTop: '0.8rem'
    },
    '& .no-schedule-wrap': {
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        textAlign: 'center',
        '& .text': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: `rgba(${CColorCodeVariables.slateBlue}, 1)`
        }
    },

    '& .sc-label': {
        fontWeight: 500,
        fontSize: '14px',
        color: 'rgba(100, 116, 139, 1)'
    },

    '& .sc-form-control': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 10px', 
        alignItems: 'center',

        '& .sc-input': {
            borderRadius: '4px',
            backgroundColor: 'rgba(100, 116, 139, 0.1)',
            padding: '5px 10px',
            outline: 'none',
            borderStyle: 'unset',
            color: 'rgba(100, 116, 139, 1)',
            width: '100px',

            '&:focus-visible': {
                outline: 'unset'
            },
        }
    },

    '& .sc-btn': {
        boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.6)",
                color: "rgba(255, 255, 255, 1)",
                margin: '15px 10px 0px 10px',
                width: "90%",
                background: 'linear-gradient(84.85deg, #00B09B -21.2%, #3DB0C9 87.5%)',
    }
}));