import { PaletteMode } from "@mui/material";
import { CColorCodeVariables } from "./color.constants";

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                primary: {
                    main: '#23be6a',
                    contrastText: `rgba(${CColorCodeVariables.white},0.87)`,
                },
                secondary: {
                    main: '#f5004e',
                },
                divider: '#EFEFEF',
                neutral: {
                    main: '#64748B',
                    contrastText: '#fff',
                },
                color: {
                    green: '#10B981',
                    green1: '#00B09B',
                    green2: '#96C93D',
                    green3: '#409576',
                    green4: '#23BE6A',
                    green5: '#6FE4A5',
                    white: '#FFF',
                    white1: '#FFF',
                    white2: '#E0E0E0',
                    white3: '#E2E4E5',
                    grey: '#E1E5EA',
                    grey1: '#F1F1F2',
                    grey2: '#9E9E9E',
                    grey3: '#CACED3',
                    grey4: `rgba(${CColorCodeVariables.darkGray}, 0.2)`,
                    grey5: '#737373',
                    grey6: `rgba(${CColorCodeVariables.gunmetalGray}, 0.1)`,
                    grey7: '#A8AEB1',
                    grey8: '#C5C9CB',
                    grey9: '#616161',
                    black: '#000',
                    black1: '#3C4043',
                    black2: `rgba(${CColorCodeVariables.gunmetalGray})`,
                    red1: '#FF4646',
                    yellow300: '#FCD34D',
                    subTextGrey: `rgba(${CColorCodeVariables.slateGray}, 0.6)`
                },
                background: {
                    paper: '#fff',
                    default: `rgba(${CColorCodeVariables.paleGray}, 0.9)`,
                },
            }
            : {
                primary: {
                    main: '#4FE3AE',
                    contrastText: `rgba(${CColorCodeVariables.white},0.87)`,
                },
                secondary: {
                    main: '#f5004e',
                },
                divider: `rgba(${CColorCodeVariables.darkGray00})`,
                neutral: {
                    main: '#64748B',
                    contrastText: '#fff',
                },
                color: {
                    green: '#10B981',
                    green1: '#00B09B',
                    green2: '#96C93D',
                    green3: '#409576',
                    green4: '#23BE6A',
                    green5: '#6FE4A5',
                    white: '#000',
                    white1: '#1F1F1F',
                    white2: '#3C4043',
                    white3: '#000',
                    grey: '#E1E5EA',
                    grey1: '#F1F1F2',
                    grey2: '#9E9E9E',
                    grey3: '#CACED3',
                    grey4: `rgba(${CColorCodeVariables.darkGray}, 0.2)`,
                    grey5: '#737373',
                    grey6: `rgba(${CColorCodeVariables.gunmetalGray}, 0.1)`,
                    grey7: '#A8AEB1',
                    grey8: '#C5C9CB',
                    grey9: '#616161',
                    black: '#fff',
                    black1: '#3C4043',
                    black2: `rgba(${CColorCodeVariables.gunmetalGray})`,
                    red1: '#FF4646',
                    yellow300: '#FCD34D',
                    subTextGrey: `rgba(${CColorCodeVariables.slateGray}, 0.6)`
                },
                background: {
                    paper: '#1e1e1e',
                    default: '#24292e',
                },
            }),
    },
    ...(mode === 'light'
        ? {
            spacing: 8,
            typography: {
                fontFamily: 'Barlow',
                fontWeightLight: 300,
                h5: {
                    fontSize: '1.3rem !important',
                    fontWeight: 600,
                    color: '#3C4043',
                },
                h1: {
                    fontSize: '1.5rem !important',
                    fontWeight: 600,
                    color: '#3C4043',
                },
                h2: {
                    fontSize: '1.3rem !important',
                    fontWeight: 500,
                    color: '#3C4043',
                },
                subtitle1: {
                    fontSize: '0.9rem',
                },
                button: {
                    textTransform: 'none' as const,
                },
            },
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            fontSize: '.875rem',
                            color: '#3C4043',
                            borderRadius: '5px',
                            borderColor: '#CACED3',
                        },
                    },
                },
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            fontSize: '.875rem',
                        },
                        h1: {
                            fontSize: '1.5rem',
                            fontWeight: 600,
                            color: '#3C4043',
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: '.875rem',
                            fontWeight: 500,
                            color: '#737373',
                            marginBottom: '.5rem',
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: '5px',
                        },
                    },
                },
            },
        }
        : {
            spacing: 8,
            typography: {
                fontFamily: 'Barlow',
                fontWeightLight: 300,
                h5: {
                    fontSize: '1.3rem !important',
                    fontWeight: 600,
                    color: '#fff',
                },
                h1: {
                    fontSize: '1.5rem !important',
                    fontWeight: 600,
                    color: '#fff',
                },
                h2: {
                    fontSize: '1.3rem !important',
                    fontWeight: 500,
                    color: '#fff',
                },
                subtitle1: {
                    fontSize: '0.9rem',
                },
                button: {
                    textTransform: 'none' as const,
                },
            },
            components: {
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            fontSize: '.875rem',
                            color: '#fff',
                            borderRadius: '5px',
                            borderColor: '#CACED3',
                        },
                    },
                },
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            fontSize: '.875rem',
                        },
                        h1: {
                            fontSize: '1.5rem',
                            fontWeight: 600,
                            color: '#fff',
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            fontSize: '.875rem',
                            fontWeight: 500,
                            color: '#fff',
                            marginBottom: '.5rem',
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: '5px',
                        },
                    },
                },
            },
        }),
});

export const MUI_THEME_MODE: any = {
    DARK: 'dark',
    LIGHT: 'light'
}

export const WINDOW_INNER_WIDTH = {
    MOBILE: 768,
    MOBILE_TABLET_MIXED: 820
}