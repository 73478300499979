import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WINDOW_INNER_WIDTH } from "../../constants/themes.constants";
import type { FC } from 'react';
import { SignupSuccessAndUnsuccessPageStyled } from "../signup/SignupPageStyles";
import { Box, Button, Grid, Typography } from "@mui/material";
import SignUpSuccessIcon from '../../assets/icons/3d-casual-life-check-mark-in-box-side-view-blue.svg';

interface SignupSuccessPageProps {
}

const SignupSuccessPage: FC<SignupSuccessPageProps> = ({ ...rest }) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <SignupSuccessAndUnsuccessPageStyled container ismobile={isMobile.toString()}>
            <Box className="box-wrap">
                <Grid container className="grid-container">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h3" className="title">
                            Successful Registration
                        </Typography>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h3" className="content">
                            You will have the login access once the System Administrator has approved your request!
                        </Typography>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="icon-wrap">
                        <img className="reg-icon" src={SignUpSuccessIcon} alt="registration-success" />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="btn-wrap">
                        <Button type="button" variant="contained" className="back-btn" onClick={() => navigate('/login')}>BACK TO LOGIN</Button>
                    </Grid>
                </Grid>
            </Box>
        </SignupSuccessAndUnsuccessPageStyled>
    );
}
export default SignupSuccessPage;
