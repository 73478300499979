import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import setupInterceptors from './apiService';
import { useAppDispatch } from '../../store/hooks';

function InjectAxiosInterceptors() {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setupInterceptors(history, location, dispatch);
  }, [history, location, dispatch]);

  return null;
}

export default InjectAxiosInterceptors;
