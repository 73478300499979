export const QUERY_KEY = {
  GET_DEVICE_STATUS_BY_ID: 'GET_DEVICE_STATUS_BY_ID',
  GET_DEVICE_HISTORICAL_DATA: 'GET_DEVICE_HISTORICAL_DATA',
  GET_FARM_DETAILS: 'GET_FARM_DETAILS',
  GET_DEVICE_STATUS_OVERVIEW: 'GET_DEVICE_STATUS_OVERVIEW',
  GET_FARM_CONTROL_STATUS: 'GET_FARM_CONTROL_STATUS',
  GET_DEVICE_SCHEDULE: "GET_DEVICE_SCHEDULE",
  GET_CCTV_KEEP_ALIVE: "GET_CCTV_KEEP_ALIVE",
  GET_SENSOR_HISTORICAL_DATA: `GET_SENSOR_HISTORICAL_DATA`,
  GET_AVERAGE_SENSOR_READING_DATA: 'GET_AVERAGE_SENSOR_READING_DATA'
}