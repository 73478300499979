import { Container } from "@mui/material"
import { ReactNode } from "react"
import { CColorCodeVariables } from "../constants/color.constants"

const ConfirmationTemplate = (props: {
    header: string,
    subHeader: string,
    subHeader2?: string,
    icon: string,
    children?: ReactNode
}) => {
    return <div
    style={{
      backgroundColor: `rgba(${CColorCodeVariables.shadowSlate}, 0.2)`,
      minHeight: "100vh",
      display: "flex",
    }}
  >
    <Container
      sx={{ margin: "auto", textAlign: "center", width: "fit-content" }}
    >
      <h1 style={{ color: "#3C404380", marginTop: "1rem" }}>
        {props?.header}
      </h1>
      <p style={{ color: "#00000080" }}>
        {props?.subHeader}
      </p>
      <img style={{ margin: '2rem 0' }} src={props?.icon} alt="forgot-password" />
      {props?.children}
    </Container>
  </div>
}

export default ConfirmationTemplate