import { styled } from "@mui/material";

export const TimePickerStyle = styled('input')(() => ({
    borderRadius: '4px',
    backgroundColor: 'rgba(100, 116, 139, 0.1)',
    padding: '5px 10px',
    outline: 'none',
    borderStyle: 'unset',
    color: 'rgba(100, 116, 139, 1)',
    cursor: 'pointer',

    '&:focus-visible': {
        outline: 'unset'
    },

    '&::after': {
        content: '"hrs"',
        paddingLeft: '30px'
    },

    '&::-webkit-calendar-picker-indicator': {
        display: 'none',
    }
}))

export const TimerPickerWrapper = styled('div')({
    display: 'inline-block',
    position: 'relative'
})

export const TimePopupContainer = styled('div')({
    display: 'flex',
    zIndex: '49999',
    position: 'absolute',
    left: '5px',
    top: '30px',
    border: '0.5px solid rgba(0, 0, 0, 0.2)',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)',
    background: 'rgba(245, 245, 245, 1)',
    borderRadius: '8px',
    padding: '10px 30px'
})

export const ScrollTimeWrapper = styled('div')({
    height: "200px", 
    overflow: "auto", 
    textAlign: "center",

    '&::-webkit-scrollbar': {
        display: 'none'
    }
})

export const StylePForTime = styled('p')<{ selected: boolean, disable: boolean }>(({ selected, disable }) => ({
    fontWeight: '500',
    fontSize: '18px',
    color: `rgba(100, 116, 139, ${disable ? '0.5' : '1'})`,
    padding: '10px 20px',
    cursor: disable ? 'not-allowed' : 'pointer',
    borderRadius: '5px',
    pointerEvents: disable ? 'none' : 'unset',
    background: selected ? 'linear-gradient(84.85deg, rgba(0, 176, 155, 0.1) -21.2%, rgba(61, 176, 201, 0.1) 87.5%)' : 'transparent'
}))