import {  Modal, styled } from '@mui/material';
import { CColorCodeVariables } from '../../constants/color.constants';
import backgroundImage from '../../assets/images/noise.jpeg';


export const StyledControlModal = styled(Modal)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const ModalSection = styled('section')(({ theme }) => ({
    borderRadius: '8px',
    height: '90%',
    width: '90%',
    boxSizing: 'border-box',
    overflow: 'hidden', 
    backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'repeat', 
    '&:focus-visible': {
        outline: 'unset'
    },

    [theme.breakpoints.up('xl')]: {
        width: '70%',
        height: '80%',
    }
}))

export const ControlModalHeader = styled('header')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    height: '60px',

    '@media screen and (max-width: 400px)': {
        height: '75px'
    }
});

export const ControlModalHeading = styled('h1')({
    color: `rgba(${CColorCodeVariables.shadowGray}, 0.8)`,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
    margin: '0 0 0.5rem',
})

export const ControlModalSubHeader = styled('p')({
    fontWeight: '400',
    color: `rgba(${CColorCodeVariables.black}, 0.8)`
});

export const ControlModalContent = styled('div')({
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    padding: '0px 24px 24px 24px',
    boxSizing: 'border-box',
    height: `calc(100% - 108px)`,

    '@media screen and (max-width: 400px)': {
        height: `calc(100% - 123px)`,
    }
});
