import React from "react";
import { SENSOR_STATUS_TYPES } from "../../constants/sensor.constants";
import { styled } from "@mui/material";
import OptimalIcon from '../../assets/icons/Optimal.svg';
import './CensorStatusBradge.scss';
import { CColorCodeVariables } from "../../constants/color.constants";

const BRADGE_COLORS = {
  [SENSOR_STATUS_TYPES.ACCEPTABLE]:
    "linear-gradient(84.85deg, #F5EC18 -21.2%, #E6C654 87.5%)",
  [SENSOR_STATUS_TYPES.CRITICAL]:
    "linear-gradient(84.85deg, #B0004A -21.2%, #C95F3D 87.5%)",
  [SENSOR_STATUS_TYPES.OPTIMAL]:
    `linear-gradient(84.85deg, rgba(${CColorCodeVariables.turquoiseOasis}, 0.8) -21.2%, rgba(${CColorCodeVariables.aquaBlue}, 0.8) 87.5%)`,
};

const StyledSpan = styled("span")({
  padding: "0.2rem",
  color: "#FFFFFF",
  fontSize: "0.7rem",
  borderRadius: "4px",
  height: "fit-content",
  boxShadow: `0px 2px 10px 0px rgba(${CColorCodeVariables.serenityBlue}, 0.12)`,
  fontWeight: "700",
});

const CensorStatusBradge: React.FC<{ type: string }> = ({ type }) => {
  return (
    <>
    {type === SENSOR_STATUS_TYPES.OPTIMAL && <span className="optimal-state-icon" ><img src={OptimalIcon} alt="optimal-icon" /></span>}
    <StyledSpan
      className={type === SENSOR_STATUS_TYPES.OPTIMAL ? 'optimal-state' : ''}
      style={{
        background: BRADGE_COLORS[type],
      }}
    >
      {type}
    </StyledSpan>
    </>
  );
};

export default CensorStatusBradge;
