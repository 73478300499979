import { useEffect, useState } from "react";
import type { FC } from "react";
import { WINDOW_INNER_WIDTH } from "../../constants/themes.constants";
import { SignupSuccessAndUnsuccessPageStyled } from "../signup/SignupPageStyles";
import { Box, Button, Grid, Typography, FormHelperText } from "@mui/material";
import SignUpSuccessIcon from "../../assets/icons/casual-life-3d-alarm-sign.svg";
import { IRegisterForm } from "./SignupPage";
import { LoadingButton } from "@mui/lab";

interface SignupUnsuccessPageProps {
  user: IRegisterForm;
  userRegisteration: (user: IRegisterForm) => Promise<void>;
  setRegisterFail: (value: React.SetStateAction<boolean>) => void;
  errorMessage: string;
  isLoading: boolean;
}

const SignupUnsuccessPage: FC<SignupUnsuccessPageProps> = ({
  user,
  userRegisteration,
  setRegisterFail,
  errorMessage,
  isLoading,
  ...rest
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= WINDOW_INNER_WIDTH.MOBILE_TABLET_MIXED);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SignupSuccessAndUnsuccessPageStyled
      container
      ismobile={isMobile.toString()}
    >
      <Box className="box-wrap">
        <Grid container className="grid-container">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className="title">
              Unsuccessful Registration
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className="content">
              Our system has encountered a problem! Please try again.
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormHelperText error sx={{ textAlign: "center" }}>
              {errorMessage}
            </FormHelperText>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="icon-wrap"
          >
            <img
              className="reg-icon"
              src={SignUpSuccessIcon}
              alt="registration-success"
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="btn-wrap"
          >
            <LoadingButton
              loading={isLoading}
              onClick={() => userRegisteration(user)}
              type="button"
              variant="contained"
              className="back-btn"
            >
              TRY AGAIN
            </LoadingButton>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="btn-wrap"
          >
            <Button
              onClick={() => setRegisterFail(false)}
              type="button"
              variant="contained"
              className="return-btn"
            >
              RETURN
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SignupSuccessAndUnsuccessPageStyled>
  );
};
export default SignupUnsuccessPage;
