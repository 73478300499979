import * as S from './StyledControlStatusChartSkeleton'
import { Skeleton } from "@mui/material";

const ControlStatusChartSkeleton = () => {
    return <S.StyledControlStatusChartSkeleton>
        <div className='header'>
            <Skeleton className="title" animation="wave" />
            <Skeleton className="sub-title" animation="wave" />
        </div>

        <div className='content'>
            <div className='title-wrap'>
                <div></div>
                <Skeleton className="title" animation="wave" />
                <Skeleton className="chart-type" animation="wave" />
            </div>
            <div className='chart-wrap'>
                <Skeleton className="chart" animation="wave" />
            </div>
            <Skeleton className="slider" animation="wave" />
        </div>
    </S.StyledControlStatusChartSkeleton>
}

export default ControlStatusChartSkeleton