import React, {useCallback, useEffect, useRef, useState} from "react";
import {StyledSlider} from "./GradientSliderStyles";
import {IGradientColors, IRGBColorObj} from "../../interfaces/app-interfaces";

const GradientSlider: React.FC<{
  value: number | undefined;
  colors: IGradientColors[];
  range: any;
}> = ({ colors, value = 1 , range = {min:0, max:10}}) => {
    
  const [railColor, setRailColor] = useState<string>("");
  const sliderRef = useRef<HTMLElement>(null);
  const getColorAtPoint = useCallback(
    (gradientStops: IGradientColors[], pointPosition: any) => {
      // Sort gradient stops by position
      gradientStops.sort(
        (a: IGradientColors, b: any) => a.position - b.position
      );

      // Find the two gradient stops that the point falls between
      let lowerStop, upperStop;
      for (let i = 0; i < gradientStops.length - 1; i++) {
        if (
          gradientStops[i].position <= pointPosition &&
          gradientStops[i + 1].position >= pointPosition
        ) {
          lowerStop = gradientStops[i];
          upperStop = gradientStops[i + 1];
          break;
        }
      }

      // Interpolate between the colors of these two stops
      if (lowerStop && upperStop) {
        const t =
          (pointPosition - (lowerStop?.position || 0)) /
          ((upperStop?.position || 0) - (lowerStop?.position || 0));
        return interpolateColor(lowerStop?.color, upperStop?.color, t);
      }
      return { r: 0, g: 0, b: 0 };
    },
    []
  );

  useEffect(() => {
    if (sliderRef) {
      const railWidth = sliderRef?.current?.offsetWidth || 0;
      const railChildrenArray = sliderRef?.current?.children;
      if (railChildrenArray?.length) {
        const thumbStyle = window.getComputedStyle(railChildrenArray[2]);
        const pointPosition =
          (+(thumbStyle.left?.replace("px", "") || "") / railWidth) * 100; // Position of the point along the gradient line
        const color = getColorAtPoint(colors, pointPosition);
        setRailColor(`rgba(${color.r}, ${color.g}, ${color.b}, 0.5)`);
      }
    }
  }, [sliderRef, colors, getColorAtPoint]);

  // Function to interpolate between two colors
  const interpolateColor = (
    color1: IRGBColorObj,
    color2: IRGBColorObj,
    t: number
  ) => {
    const r = Math.round(color1?.r + (color2.r - color1.r) * t);
    const g = Math.round(color1?.g + (color2.g - color1.g) * t);
    const b = Math.round(color1.b + (color2.b - color1.b) * t);
    return { r, g, b };
  };
  return (
    <StyledSlider
      ref={sliderRef}
      value={value}
      max={range.max}
      min={range.min}
      step={1}
      thumbcolor={railColor}
      disabled
      colors={colors?.map(
        (item) => `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`
      )}
    />
  );
};

export default GradientSlider;
