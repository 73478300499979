import ConfirmationTemplate from "../../common/ConfirmationTemplate";
import ForgotPasswordIcon from "../../assets/icons/forgot-password.svg";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import {
  CheckCircleRounded,
  ErrorRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import * as S from "../forgot-password/ForgotPasswordPageStyles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import {
  numberExist,
  symbolExist,
  upperCaseExist,
} from "../../common/utils/regexChecks";
import { PasswordErrorWrapper } from "./ChangePasswordStyles";
import { setNewPassword } from "../../services/api-service/authApi";
import { TOAST_TYPES, showToast } from "../../store/slices/toastSlice";
import { useAppDispatch } from "../../store/hooks";
import { CColorCodeVariables } from "../../constants/color.constants";

const passwordErrorList = [
  {
    key: 1,
    text: "Password must be at least 8 characters",
    condition: (value: string) => value.length >= 8,
  },
  {
    key: 2,
    text: "Password must contain at least one uppercase letter",
    condition: (value: string) => upperCaseExist(value),
  },
  {
    key: 3,
    text: "Password must contain at least one number",
    condition: (value: string) => numberExist(value),
  },
  {
    key: 4,
    text: "Password must contain at least one symbol",
    condition: (value: string) => symbolExist(value),
  },
];

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { token = "" } = useParams();

  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isTouched, setIsTouched] = useState({
    password: false,
    confirmPassword: false,
  });
  const [isValidConfirmPassword, setIsVaildConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useAppDispatch();

  const changePassword = async () => {
    setIsLoading(true);
    const apiReponse = await setNewPassword(
      {
        password,
        confirmPassword,
      },
      token
    );
    setIsLoading(false);
    dispatch(
      showToast({
        type: apiReponse.status ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
        message: apiReponse.status
          ? "Password Changed Successfully"
          : apiReponse.response?.response?.data?.message ||
            apiReponse.response?.message ||
            "",
      })
    );
    if (apiReponse.status) {
      navigate("/login");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsTouched({
      password: true,
      confirmPassword: true
    })
    if (isValidPassword && isValidConfirmPassword) {
      changePassword();
    }
  };

  const handleChangePassword = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const password = e.target.value;
    setPassword(password);
    if (
      password.length >= 8 &&
      upperCaseExist(password) &&
      numberExist(password) &&
      symbolExist(password)
    ) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
    if (!isTouched.password) {
      setIsTouched((prevState) => ({ ...prevState, password: true }));
    }
  };

  const handleChangeConfirmPassword = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
    if (isValidPassword && confirmPassword === password) {
      setIsVaildConfirmPassword(true);
    } else {
      setIsVaildConfirmPassword(false);
    }
    if (!isTouched.confirmPassword) {
      setIsTouched((prevState) => ({ ...prevState, confirmPassword: true }));
    }
  };

  const formVaild = () => {
    return (
      isValidConfirmPassword &&
      isValidPassword &&
      isTouched.password &&
      isTouched.confirmPassword
    );
  };

  const resetPasswordForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Typography
          className="label"
          sx={{ textAlign: "left", margin: "0.7rem 0" }}
        >
          New Password
        </Typography>
        <TextField
          type={showPassword ? "text" : "password"}
          fullWidth
          className="field"
          placeholder="Enter new password"
          name="password"
          variant="outlined"
          error={!isValidPassword && isTouched.password}
          autoComplete="off"
          autoFocus
          value={password}
          disabled={isLoading}
          onChange={handleChangePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SvgIcon
                  fontSize="small"
                  sx={{
                    color:
                      !isValidPassword && isTouched?.password ? "red" : "green",
                  }}
                >
                  {!isValidPassword && isTouched?.password && <ErrorRounded />}
                  {isValidPassword && <CheckCircleRounded />}
                </SvgIcon>
                <IconButton onClick={() => setShowPassword((prevState) => !prevState)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {isTouched.password && !password.length && (
          <FormHelperText error>Password is required</FormHelperText>
        )}
        <PasswordErrorWrapper>
          {passwordErrorList?.map((errorState) => (
            <FormHelperText
              key={errorState.key}
              sx={{
                color:
                  isTouched.password && errorState.condition(password)
                    ? "#00B09BCC"
                    : "#00000080",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleRounded sx={{ marginRight: "0.2rem" }} />
              {errorState.text}
            </FormHelperText>
          ))}
        </PasswordErrorWrapper>

        <Typography
          className="label"
          sx={{ textAlign: "left", margin: "0.7rem 0" }}
        >
          Confirm New Password
        </Typography>
        <TextField
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
          className="field"
          placeholder="Enter confirm new password"
          name="confirmPassword"
          variant="outlined"
          error={!isValidConfirmPassword && isTouched.confirmPassword}
          autoComplete="off"
          value={confirmPassword}
          onChange={handleChangeConfirmPassword}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SvgIcon
                  fontSize="small"
                  sx={{
                    color:
                      !isValidConfirmPassword && isTouched.confirmPassword
                        ? "red"
                        : "green",
                  }}
                >
                  {!isValidConfirmPassword && isTouched.confirmPassword && (
                    <ErrorRounded />
                  )}
                  {isValidConfirmPassword && <CheckCircleRounded />}
                </SvgIcon>
                <IconButton onClick={() => setShowConfirmPassword((prevState) => !prevState)} edge="end">
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {isTouched.confirmPassword && !confirmPassword.length && (
          <FormHelperText error>Confirm Password is required</FormHelperText>
        )}
        {isTouched.confirmPassword && !isValidConfirmPassword && (
          <FormHelperText error>Passwords must match</FormHelperText>
        )}
        {isValidConfirmPassword && (
          <FormHelperText sx={{ color: "#00B09BCC" }}>
            Password matched
          </FormHelperText>
        )}
        <S.ResetPasswordStyledButton
          btncolor={formVaild() ? `rgba(${CColorCodeVariables.white})` : `rgba(${CColorCodeVariables.shadowSlate}, 0.4)`}
          backgroundcolor={
            formVaild()
              ? "linear-gradient(84.85deg, #00B09B -21.2%, #3DB0C9 87.5%)"
              : "#B3B3B31A"
          }
          type="submit"
          loading={isLoading}
        >
          RESET PASSWORD
        </S.ResetPasswordStyledButton>
        <Link style={{ textDecoration: "none", color: "inherit" }} to="/login">
          <S.ResetPasswordStyledButton
            btncolor="#00000099"
            backgroundcolor="#FFFFFF66"
          >
            RETURN
          </S.ResetPasswordStyledButton>
        </Link>
      </form>
    );
  };

  return (
    <ConfirmationTemplate
      header="Set New Password"
      subHeader="Your new password must be different from previously used password."
      icon={ForgotPasswordIcon}
    >
      {resetPasswordForm()}
    </ConfirmationTemplate>
  );
};

export default ChangePasswordPage;
