import { Slider, styled } from "@mui/material";

export const StyledSlider = styled(Slider)<{ colors: string[],thumbcolor: string }>(({ theme, colors = [], thumbcolor }) => ({
    padding: '0px !important',
    '& .MuiSlider-rail': { 
        background: `linear-gradient(90deg, ${colors.join(', ')})`,
        height: '0.3rem'
    },
    '& .MuiSlider-track': {
        background: 'unset',
        border: 'unset',
    },
    '& .MuiSlider-thumb': {
        color: '#FFFFFF',
        height: '0.55rem',
        width: '0.55rem',
        border: `2px solid ${thumbcolor}`
    }
}))