import { IGradientColors } from "../interfaces/app-interfaces";

export const SENSOR_STATUS_TYPES = {
  OPTIMAL: "OPTIMAL",
  ACCEPTABLE: "ACCEPTABLE",
  CRITICAL: "CRITICAL",
};

export const controlStatusSwitchLabelTypes = {
  TEXT: "text",
  ICON: "icon",
};

export const controlStatusTypes = [
  {
    label: "Intelligent Control",
    switchLabelType: controlStatusSwitchLabelTypes.TEXT,
    code: 'intelligentControl',
    id: 1,
  },
  {
    label: "Physical",
    code: 'physicalControl',
    switchLabelType: controlStatusSwitchLabelTypes.TEXT,
    id: 2,
  },
  {
    label: "Device Connection",
    code: 'connectionStatus',
    switchLabelType: controlStatusSwitchLabelTypes.ICON,
    id: 3,
  },
];

export const CENSOR_DETAILS_TYPES = {
  PH_LEVEL: "PH LEVEL",
  TEMPERATURE: "TEMPERATURE",
  DISSOLVED_OXYGEN: "DISSOLVED OXYGEN",
  AMMONIA_LEVEL: "AMMONIA LEVEL",
};

const colors = [
  { "color": { "r": 255, "g": 0, "b": 12 }, "position": 0 },
  { "color": { "r": 255, "g": 87, "b": 11 }, "position": 7.14 },
  { "color": { "r": 255, "g": 127, "b": 0 }, "position": 14.28 },
  { "color": { "r": 255, "g": 194, "b": 0 }, "position": 21.42 },
  { "color": { "r": 255, "g": 240, "b": 0 }, "position": 28.56 },
  { "color": { "r": 100, "g": 195, "b": 9 }, "position": 35.7 },
  { "color": { "r": 0, "g": 187, "b": 53 }, "position": 42.84 },
  { "color": { "r": 0, "g": 172, "b": 64 }, "position": 49.98 },
  { "color": { "r": 0, "g": 187, "b": 181 }, "position": 57.12 },
  { "color": { "r": 31, "g": 146, "b": 209 }, "position": 64.26 },
  { "color": { "r": 53, "g": 84, "b": 167 }, "position": 71.4 },
  { "color": { "r": 92, "g": 82, "b": 165 }, "position": 78.54 },
  { "color": { "r": 106, "g": 66, "b": 161 }, "position": 85.68 },
  { "color": { "r": 118, "g": 25, "b": 133 }, "position": 92.82 },
  { "color": { "r": 74, "g": 23, "b": 112 }, "position": 100 },
]

export const CENSOR_DETAILS_TYPE_COLORS: {  [key: string] : { colors: IGradientColors[] } } = {
  [CENSOR_DETAILS_TYPES.AMMONIA_LEVEL]: {
    colors: [
      { color: { r: 243, g: 208, b: 23 }, position: 0 },
      { color: { r: 221, g: 205, b: 0 }, position: 16.67 },
      { color: { r: 173, g: 178, b: 0 }, position: 33.34 },
      { color: { r: 104, g: 149, b: 31 }, position: 50.01 },
      { color: { r: 0, g: 117, b: 26 }, position: 66.68 },
      { color: { r: 0, g: 95, b: 32 }, position: 83.35 },
      { color: { r: 0, g: 48, b: 27 }, position: 100 },
    ],
  },
  [CENSOR_DETAILS_TYPES.DISSOLVED_OXYGEN]: {
    colors: [
      { color: { r: 152, g: 146, b: 140 }, position: 0 },
      { color: { r: 200, g: 184, b: 171 }, position: 33.33 },
      { color: { r: 0, g: 144, b: 205 }, position: 66.66 },
      { color: { r: 1, g: 98, b: 140 }, position: 100 },
    ],
  },
  [CENSOR_DETAILS_TYPES.PH_LEVEL]: {
    colors: colors
  },
  [CENSOR_DETAILS_TYPES.TEMPERATURE]: {
    colors: colors
  },
};
