import { styled } from "@mui/material";
import { CColorCodeVariables } from "../../../constants/color.constants";

export const IntakeCensorReadingWrapperStyle = styled('section')(({
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.25)`,
    background: `rgba(${CColorCodeVariables.white}, 1)`,
    padding: '1rem',
    margin: '0.4rem',
    borderRadius: '4px',
    border: '1px solid transparent',
    transition: 'border-color 0.5s ease',
    '&:hover': {
        borderColor: `rgba(${CColorCodeVariables.turquoiseOasis}, 1)`
    }
}))

export const IntakeCensorNameStyle = styled('p')({
    color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
    fontWeight: '600',
    fontSize: '13px',
    marginBottom: '0.5rem',
    textTransform: 'uppercase'
})