import { styled } from "@mui/material";
import { CColorCodeVariables } from "../constants/color.constants";

export const StyledSectionHeading = styled('h3')({
    fontSize: '16px',
    fontWeight: '500',
    color: `rgba(${CColorCodeVariables.shadowSlate}, 1)`,
    margin: "0 0 0.5rem"
})

export const StyledSectionSubHeading = styled('p')(({ theme }) => ({
    color: theme?.palette?.color?.subTextGrey,
    fontSize: '14px',
    fontWeight: '400'
}))