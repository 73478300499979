import styled from "@emotion/styled";
import { CColorCodeVariables } from "../../constants/color.constants";

export const CensorReadingCard = styled('section')({
    backgroundColor: '#FFFFFF', 
    padding: '0.2rem 1rem 0.8rem', 
    borderRadius: '0.3rem', 
    flex: '1 1 12rem',
    maxWidth: '20rem',
    border: '1px solid transparent',
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    transition: 'border-color 0.5s ease',
    '&:hover': {
        borderColor: `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
    }
})

export const AvgCardCensorName = styled('h3')(({ theme }) => ({
    color: `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
    margin: '0 0 0.5rem 0',
}))