import styled from "@emotion/styled";
  import { LoadingButton } from "@mui/lab";

export const ResetPasswordStyledButton = styled(LoadingButton)<{
  btncolor: string;
  backgroundcolor: string;
}>(({ theme, btncolor, backgroundcolor }) => ({
  display: "block",
  background: backgroundcolor,
  width: "100%",
  color: btncolor,
  padding: "0.6rem 0rem",
  margin: "0.7rem 0rem",
  boxShadow: "0px 0px 1px 0px #00000099",
}));
