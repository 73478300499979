import { useEffect, useState } from "react";
import IntakeCensorReading from "../intake-censor-reading/IntakeCensorReading";
import * as S from "./IntakeReadingStyles";
import IntakeNavigator from "./intake-navigator/IntakeNavigator";
import IntakeLoadingSkeleton from "./intake-loading-skeleton/IntakeLoadingSkeleton";
import {
  StyledSectionHeading,
  StyledSectionSubHeading,
} from "../../styled-components/StyledSectionHeading";
import {getSensorHistoricalData} from "../../services/api-service/apiService";

const IntakeReading = (props: {intakeDevices:any, readingParams:any}) => {
  const [currentIntake, setCurrentIntake] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [intakeData, setIntakeData] = useState([]);
  const intake = props?.intakeDevices?.find((item:any) => item.name === 'Intake');
  const devicesWithIds = intake?.devices?.map((device: any, index:number) => ({
    ...device,
    id: index + 1
  }));

  const handleIntakeChange = (intake: number) => {
    setCurrentIntake(intake);
  };

  const getIntakeDetails = async (intake: number) => {
    setIsLoading(true);
    const data = devicesWithIds?.find((item:any) => item.id === intake);
    if (data?.deviceId) {
      const res = await getSensorHistoricalData(data.deviceId);
      setIntakeData(res.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getIntakeDetails(currentIntake).then(r => {});
    // eslint-disable-next-line
  }, [currentIntake, props.intakeDevices]);

  return (
    <S.IntakeReadingWrapper>
      <S.IntakeCardHeaderStyle>
        <div>
          <StyledSectionHeading>Intake Reading</StyledSectionHeading>
          <StyledSectionSubHeading>Reading Trends</StyledSectionSubHeading>
        </div>
        <IntakeNavigator
          handleIntakeChange={handleIntakeChange}
          selectedIntake={currentIntake}
          intakes={devicesWithIds}
        />
      </S.IntakeCardHeaderStyle>
      {isLoading ? <IntakeLoadingSkeleton /> : <IntakeCensorReading data={intakeData} waterQualityParams={props.readingParams}/>}
    </S.IntakeReadingWrapper>
  );
};

export default IntakeReading;
