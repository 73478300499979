import { Outlet } from "react-router-dom"
import InjectAxiosInterceptors from "../services/api-service/injectAxiosInterceptors"
import { useEffect } from "react"
import { userAuthorization } from "../services/api-service/authApi"
import { useAppDispatch } from "../store/hooks"
import { saveUserDetails } from "../store/slices/userSlice"

const Root = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        getUserAuth()
    })

    const getUserAuth = async () => {
        const apiResponse = await userAuthorization();
        if (apiResponse?.status) {
            dispatch(saveUserDetails(apiResponse?.response?.data))
        }
    }

    return <>
    <InjectAxiosInterceptors />
    <Outlet />
    </>
}
export default Root