import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { CColorCodeVariables } from "../../../../constants/color.constants";

export const StyledFeederScheduleList = styled(Box)({
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    boxSizing: 'border-box',
    '& .schedule-item': {
        height: '36px',
        borderRadius: '4px',
        padding: '8px 12px',
        color: `rgba(${CColorCodeVariables.slateBlue}, 0.4)`,
        border: `0.5px dashed rgba(${CColorCodeVariables.slateBlue}, 0.4)`,
        marginBottom: '8px',
        boxSizing: 'border-box',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '10px',
        '&:last-of-type': {
            marginBottom: '0px'
        },
        '& .icon-wrap': {
            cursor: 'pointer',
            display: 'none'
        }
    },

    '& .selcted-schedule': {
        border: `2.0px solid rgba(${CColorCodeVariables.aquaBlue})`,
        '& .icon-wrap': {
            display: 'block'
        },
    }
});