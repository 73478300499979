import { FC, useEffect, useState } from "react"
import { StyledConfigSwitch } from "./StyledConfigSwitch";
import ControlSwitchConfirmationModal from "../../components/farm-controls/control-switch-conformation-modal/ControlSwitchConfirmationModal";
import {sendCommandToDevice} from "../../services/api-service/apiService";
import {useMutation} from "react-query";

interface ConfigSwitchProps {
    checked: boolean;
    name: string;
    isActiveSwitch?: boolean;
    disabled?: boolean;
    size?: 'lg';
    conformationModal?: boolean;
    handleSwitchChange?: () => void;
    deviceId?: string;
    deviceTopic?: string;
    type?: string;
    handleTimerEnabling?: any;
}

const ConfigSwitch: FC<ConfigSwitchProps> = ({ checked, name,deviceTopic, deviceId, isActiveSwitch, disabled, size, conformationModal = true, handleSwitchChange = () => {}, type , handleTimerEnabling}) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked])

    useEffect(() => {
        if(type==='timer') setIsChecked(checked);
    }, [name, type, checked]);

    const handleToggle = () => {
        if (conformationModal) {
            setOpenModal(true)
        } else {
            handleSwitchChange()
        }
    };

    const getControlType = () => {
        if (type === 'timer') {
            return 'Timer';
        } else if (type === 'Feeders') {
            return 'Feeder';
        } else {
            return 'Aerator';
        }
    }

    const sendCommandToDeviceMutation = useMutation(sendCommandToDevice, {
        onSuccess: (res) => {
        },
        onError: (error) => {
        }
    });

    const handleConformation = () =>  {
        if (type === 'timer') {
            setIsChecked(!isChecked);
            handleTimerEnabling(!isChecked);
            handleModalClose();
        } else {
            const command = {
                command: "powerToggle",
                topic: deviceTopic,
                message: "2"
            }
            setIsChecked(!isChecked);
            sendCommandToDeviceMutation.mutate(command);
            handleModalClose();
        }
    }

    const handleModalClose = () => {
        setOpenModal(false);
    }

    return (
        <StyledConfigSwitch size={size} activeswitch={isActiveSwitch?.toString() || 'false'} disabledswitch={disabled ? disabled.toString() : 'false'}>
            {isChecked ? <span className="on">ON</span> : <></>}
            <input className="switch-input" type="checkbox" id={name} checked={isChecked}
                onChange={() => {
                    if (!disabled) handleToggle();
                }} />
            <label htmlFor={name} className="switch-label"></label>
            {!isChecked ? <span className="off">OFF</span> : <></>}
            <ControlSwitchConfirmationModal open={openModal} type={isChecked ? 'off': 'on'} control={getControlType()} handleModalClose={handleModalClose} handleConformation={handleConformation}   />
        </StyledConfigSwitch>
    )
}

export default ConfigSwitch;