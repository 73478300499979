import { Grid, Paper, Skeleton, useTheme } from "@mui/material";
import React from "react";

const arrayDummy = [{}, {}, {}, {}];

const IntakeLoadingSkeleton = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        marginTop: "1.5rem",
        [theme.breakpoints.down("sm")]: {
          // Styles applied for screens equal to or wider than 600px (sm breakpoint)
          marginTop: "0.5rem",
        },
      }}
    >
      <Grid item lg={3} xl={3} md={12} sm={12} xs={12}>
        <Grid container>
          {arrayDummy?.map((item, index) => (
            <Grid
              sx={{ padding: "0.5rem" }}
              key={index}
              item
              lg={6}
              xl={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Paper sx={{ padding: "1rem" }}>
                <Skeleton
                  sx={{ width: "100%", fontSize: "2rem" }}
                  animation={false}
                  variant="text"
                />
                <Skeleton
                  variant="text"
                  sx={{ width: "50%", fontSize: "2rem" }}
                  animation={false}
                />
                <Skeleton
                  sx={{
                    width: "100%",
                    marginBottom: "0.5rem",
                    fontSize: "4rem",
                  }}
                  variant="text"
                  animation="wave"
                />
                <Skeleton />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid sx={{ padding: "0.5rem" }} item lg={9} xl={9} md={12} sm={12} xs={12}>
        <Paper sx={{ padding: "0.5rem" }}>
          <Skeleton sx={{ fontSize: '2rem', width: '20%' }} animation={false} />
          <Skeleton
            sx={{ width: "100%", fontSize: "18rem", borderRadius: "5px" }}
            variant="rectangular"
          />
          <Skeleton sx={{ fontSize: "2rem" }} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default IntakeLoadingSkeleton;
