import React from "react";
import * as S from "./IntakeNavigatorStyles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Hidden } from "@mui/material";

const IntakeNavigator: React.FC<{
  selectedIntake: number;
  handleIntakeChange: (intake: number) => void;
  intakes: any;
}> = ({ selectedIntake, handleIntakeChange, intakes }) => {

  return (
    <S.IntakeNavWrapper>
      <Hidden xsDown mdDown>
        <S.StyledNavIconButton
          onClick={() =>
            selectedIntake !== 1 && handleIntakeChange(--selectedIntake)
          }
          children={<KeyboardArrowLeft />}
        />
      </Hidden>
      {intakes?.map((IntakeButton:any) => (
        <S.IntakeStyledNavButton
          onClick={() => handleIntakeChange(IntakeButton?.id)}
          selectedintake={selectedIntake}
          tabIndex={IntakeButton?.id}
          startIcon={
            selectedIntake === IntakeButton?.id && <S.StyledChartLineIcon />
          }
          key={IntakeButton?.id}
        >
          {IntakeButton?.name}
        </S.IntakeStyledNavButton>
      ))}
      <Hidden xsDown mdDown>
        <S.StyledNavIconButton
          onClick={() =>
            selectedIntake !== intakes?.length &&
            handleIntakeChange(++selectedIntake)
          }
          children={<KeyboardArrowRight />}
        />
      </Hidden>
    </S.IntakeNavWrapper>
  );
};

export default IntakeNavigator;
