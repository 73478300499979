import { Box, styled } from '@mui/material';
import { CColorCodeVariables } from '../../../constants/color.constants';

export const StyledIntelligentControlSwitch = styled(Box)<{ isactive: string }>(({ isactive,theme }) => ({
    minWidth: '320px',
    flex: '1 1',
    padding: '12px 20px 12px 20px',
    borderRadius: '8px',
    background: `rgba(${CColorCodeVariables.paleSilver}, 1)`,
    boxShadow: `0px 0px 1px 0px rgba(${CColorCodeVariables.black}, 0.4)`,
    marginRight: '10px',
    marginBottom: '10px',

    [theme.breakpoints.down('sm')]: {
        minWidth: '80%'
    },

    '&:last-of-type': {
        marginRight: '0px'
    },
    ...(isactive === 'true' ? { background: 'linear-gradient(84.85deg, #00B09B -21.2%, #3DB0C9 87.5%),linear-gradient(0deg, #64748B, #64748B)' } : {}),
    '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        '& .title-wrap': {
            '& .title': {
                fontWeight: 600,
                fontSize: '28px',
                color: (isactive === 'true') ? `rgba(${CColorCodeVariables.white}, 1)` : `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
            }
        },
        '& .img-indicator': {
            width: '55px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    '& .content': {
        '& .para-row': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '10px',
            '& .label-col': {
                fontWeight: 400,
                fontSize: '14px',
                marginRight: '10px',
                color: (isactive === 'true') ? `rgba(${CColorCodeVariables.white}, 1)` : `rgba(${CColorCodeVariables.charcoalGray}, 1)`,
            },
            '& .status-col': {
            },
        }
    },
    '& .footer': {
        '& .history': {
            '& .clock': {
                marginRight: '5px',
                '&-icon': {
                    position: 'relative',
                    top: '4px',
                }
            },
            '& .date-time': {
                fontWeight: 400,
                fontStyle: 'italic',
                fontSize: '12px',
                color: (isactive === 'true') ? `rgba(${CColorCodeVariables.white}, 1)` : `rgba(${CColorCodeVariables.charcoalGray}, 1)`,

            }
        }
    }
}));