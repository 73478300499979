import { ILoginFormPayload } from "../../interfaces/auth.interface";
import { AxiosResponse } from "axios";
import { sendGetRequest, sendPatchRequest, sendPostRequest } from "./apiService";
import { IUserRegisteration } from "../../pages/signup/SignupPage";

export const login = async (payload: ILoginFormPayload) => {
  try {
    const response = await sendPostRequest(`/auth/login`, payload);
    return { status: true, response: response?.data };
  } catch (error: any) {
    return { status: false, response: error?.data || {} };
  }
};

//  set New password
export const setNewPassword = async (
  data: {
    password: string;
    confirmPassword: string;
  },
  token: string
) => {
  try {
    const response: AxiosResponse<any, any> = await sendPatchRequest(
      `/auth/change-password/${token}`,
      data
    );
    return { status: true, response };
  } catch (error: any) {
    return { status: false, response: error };
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const response: AxiosResponse<any, any> = await sendPostRequest('/auth/forgot-password', {
      email
    })
    return { status: true, response }
  } catch (error: any) {
    return { status: false, response: error }
  }
}

export const userAuthorization = async () => {
  try {
    const response: AxiosResponse<any, any> = await sendGetRequest('/auth/me')
    return { status: true, response }
  } catch (error: any) {
    return { status: false, reponse: error }
  }
}

export const userRegister = async (user: IUserRegisteration) => {
  try {
    const response: AxiosResponse<any, any> = await sendPostRequest('/user', user)
    return { status: true, response }
  } catch (error: any) {
    return { status: false, response: error }
  }
} 

export const logout = async (id: string) => {
  try {
    const response: AxiosResponse<any, any> = await sendPatchRequest(`/session-login/${id}`)
    return { status: true, response }
  } catch (error: any) {
    return { status: false, response: error }
  }
}
