import { CColorCodeVariables } from "./color.constants";

export const DATA_ZOOM_CONFIG = [
    {
      type: 'inside',
      start: 90,
      end: 100
    },
    {
      start: 0,
      end: 10,
      backgroundColor: `rgba(${CColorCodeVariables.slateBlue}, 0.1)`,
      fillerColor: `rgba(${CColorCodeVariables.white}, 1)`,
      moveHandleSize: 0,
      handleSize: '75%',
      handleStyle: {
        color: `rgba(${CColorCodeVariables.slateBlue}, 1)`,
        y: -10
      },
      handleIcon: 'path://M0,15V6.40231C0,6.14416,0.0998341,5.89601,0.278613,5.70978l5.476027,-5.70528c0.418433,-0.435786,1.124822,-0.403864,1.502266,0.067938l4.523017,5.65369c0.141838,0.17731,0.219995,0.397621,0.219995,0.624688V14.8C11.8,15.6,11.5,16,11,16H1C0.5,16,0.2,15.6,0,15Z'
    }
  ]