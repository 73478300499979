import { ILocalStorageItems, INotificationAlertPropertyObject, IToastTypes, } from "../interfaces/service.interface"
import { CColorCodeVariables } from "./color.constants";

export const CLocalStorageItems: ILocalStorageItems = {
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    isAuthenticated: 'isAuthenticated',
    sessionLogin: 'sessionLogin',
}

export const CToastTypes: IToastTypes = {
    success: 'success',
    error: 'error',
    warning: 'warning'
}

export enum ENotificationAlertTypes {
    info = "info",
    success = "success",
    error = "error",
    warning = "warning",
}

export const CNotificationAlertProperties: Record<ENotificationAlertTypes, INotificationAlertPropertyObject> = {
    [ENotificationAlertTypes.info]: { backgroundColor: `rgba(${CColorCodeVariables.slateBlue}, 1)` },
    [ENotificationAlertTypes.success]: { backgroundColor: 'linear-gradient(84.85deg, #00B09B -21.2%, #3DB0C9 87.5%)' },
    [ENotificationAlertTypes.error]: { backgroundColor: 'linear-gradient(84.85deg, #B0004A -21.2%, #C95F3D 87.5%)' },
    [ENotificationAlertTypes.warning]: { backgroundColor: 'linear-gradient(84.85deg, #F5EC18 -21.2%, #E6C654 87.5%)' },
}

export type INotificationAlertProperties = {
    [K in keyof typeof ENotificationAlertTypes]: { backgroundColor: string };
};