import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { IToastState } from "../../interfaces/service.interface";
import { AlertColor } from "@mui/material";

const initialState: IToastState[] = []

export const TOAST_TYPES: { [key: string]: AlertColor } = {
    SUCCESS: 'success',
    ERROR: 'error'
}

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToast: (state, action: {
            payload: {
                type: AlertColor,
                message: string
            },
            type: string;
        }) => {
            state.push({ ...action?.payload, key: state.length+1, open: true });
        },
        hideToast: (state, action) => {
            return state?.filter((item) => item?.key !== action?.payload) 
        }
    }
})

export const toastDetails = (state: RootState) => state.toast

export default toastSlice.reducer

export const { showToast, hideToast } = toastSlice.actions