import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const StyledFormControls = styled(Grid)<{ }>(({ theme }) => ({
    '& .grid-intelligent-switch-wrap': {
        height: 'auto',
        width: '100%',
        overflowY: 'hidden',
        overflowX: 'scroll',
        display: 'flex',
        marginBottom: '10px',
        scrollBehavior: "smooth",
    }
}));