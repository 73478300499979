import {
  FormHelperText,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import ForgotPassword from "../../assets/icons/forgot-password.svg";
import { CheckCircleRounded, ErrorRounded } from "@mui/icons-material";
import * as S from "./ForgotPasswordPageStyles";
import { Link } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import ConfirmationTemplate from "../../common/ConfirmationTemplate";
import EmailSentIcon from "../../assets/icons/email-sent.svg";
import { checkEmail } from "../../common/utils/regexChecks";
import { forgotPassword } from "../../services/api-service/authApi";
import { useAppDispatch } from "../../store/hooks";
import { TOAST_TYPES, showToast } from "../../store/slices/toastSlice";
import { CColorCodeVariables } from "../../constants/color.constants";


const ForgotPasswordPage = () => {
  const [isResetMailSend, setIsResetMailSend] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const passwordChangeTrigger = async () => {
    setIsLoading(true)
    const apiReponse = await forgotPassword(email)
    setIsLoading(false)
    dispatch(showToast({
      type: apiReponse.status ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR,
      message: apiReponse.status ? 'Password reset mail sent Successfully' : apiReponse.response?.message || ''
    }))
    if (apiReponse.status) {
      setIsResetMailSend(true)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsTouched(true)
    if (isValidEmail) {
      passwordChangeTrigger()
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(e.target.value);
    checkEmail(e.target.value) ? setIsValidEmail(true) : setIsValidEmail(false)
    setIsTouched(true)
  };

  const forgotForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Typography
          className="label"
          sx={{ textAlign: "left", marginBottom: "0.5rem" }}
        >
          Email Address
        </Typography>
        <TextField
          type="email"
          fullWidth
          className="field"
          placeholder="Email Address"
          name="email"
          autoFocus
          variant="outlined"
          disabled={isLoading}
          error={isTouched ? !isValidEmail : false}
          autoComplete="off"
          value={email}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SvgIcon
                  fontSize="small"
                  sx={{ color: !isValidEmail ? "red" : "green" }}
                >
                  {!isValidEmail && isTouched && <ErrorRounded />}
                  {isValidEmail && <CheckCircleRounded />}
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
        {!email && isTouched && <FormHelperText error>Email is required</FormHelperText>}
        {!isValidEmail && isTouched && (
          <FormHelperText error>Must be a valid email</FormHelperText>
        )}
        {isValidEmail && (
          <FormHelperText sx={{ color: "#249E6A" }}>
            Vaild Email Address
          </FormHelperText>
        )}
        {!isTouched && <FormHelperText>Use your registered email address</FormHelperText>}
        <S.ResetPasswordStyledButton
        loading={isLoading}
          btncolor={isValidEmail && isTouched ? `${CColorCodeVariables.white}` : `rgba(${CColorCodeVariables.shadowSlate}, 0.4)`}
          backgroundcolor={
            isValidEmail && isTouched
              ? "linear-gradient(84.85deg, #00B09B -21.2%, #3DB0C9 87.5%)"
              : "#B3B3B31A"
          }
          type="submit"
        >
          RESET PASSWORD
        </S.ResetPasswordStyledButton>
        <Link style={{ textDecoration: "none", color: "inherit" }} to="/login">
          <S.ResetPasswordStyledButton
            btncolor="#00000099"
            backgroundcolor="#FFFFFF66"
          >
            RETURN
          </S.ResetPasswordStyledButton>
        </Link>
      </form>
    );
  };

  return isResetMailSend ? (
    <ConfirmationTemplate
      header="Check Your Email"
      subHeader="We have sent the link in the email to reset your password."
      subHeader2="Be sure to check your spam folder if the email isn’t in your inbox."
      icon={EmailSentIcon}
    >
      <Link style={{ textDecoration: "none", color: "inherit" }} to="/login">
        <S.ResetPasswordStyledButton
          btncolor="#FFFFFF"
          backgroundcolor="linear-gradient(84.85deg, #00B09B -21.2%, #3DB0C9 87.5%)"
        >
          RETURN TO LOGIN PAGE
        </S.ResetPasswordStyledButton>
      </Link>
      <S.ResetPasswordStyledButton
        btncolor="#00000099"
        backgroundcolor="#FFFFFF66"
        onClick={passwordChangeTrigger}
      >
        SEND VERIFICATION EMAIL AGAIN
      </S.ResetPasswordStyledButton>
    </ConfirmationTemplate>
  ) : (
    <ConfirmationTemplate
      header="Forgot Your Password?"
      subHeader="Enter your email address and we’ll send you a link to reset your password"
      icon={ForgotPassword}
    >
      {forgotForm()}
    </ConfirmationTemplate>
  );
};

export default ForgotPasswordPage;
