import {getLocalStorageValue} from "../../services/local-storage-service/localStorage";
import {CLocalStorageItems} from "../../constants/service.contants";
import {jwtDecode} from "jwt-decode";

export const isValidToken = () => {
    const accessToken: string | null = getLocalStorageValue(CLocalStorageItems.accessToken);
    if (!accessToken) return false;
    try {
        const jwt: any = jwtDecode(accessToken);
        return Date.now() / 1000 < jwt.exp;
    } catch (e) {
        return false;
    }
};