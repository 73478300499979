import styled from "@emotion/styled";
import { CColorCodeVariables } from "../../constants/color.constants";

export const HeaderWrapper = styled('header')({
    background: `rgba(${CColorCodeVariables.turquoiseOasis}, 0.8)`,
    height: '4rem',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0'
})

export const HeaderUserProfileWarp = styled('div')({
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    marginRight: '1rem'
})